<template>
  <v-dialog max-width="600px" persistent v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn icon small v-on="on" class="verde">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card dark>
      <v-card-title class="success white--text">
        Editar Categoria
      </v-card-title>
      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    v-model.trim="nombrecat"
                    label="Nombre de Categoria"
                    :rules="inputRules"
                    filled
                    rounded
                    required
                    autocomplete="off"
                    :error-messages="errorCatName"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-icon 
                    v-if="icone!=null"
                    :color="colore"
                  >
                    {{ icone }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="9" sm="9" md="9">
                  <v-text-field
                    v-model.trim="colore"
                    label="Color"
                    :rules="inputRules"
                    disabled
                    filled
                    rounded
                    required
                    autocomplete="off"
                    :error-messages="errorCatColor"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <ColorMenu 
                    :color="colore"
                    @setColor="saveColor"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-for="icono in myIcons"
                :key="icono"
                color="accent"
                class="white--text"
                icon
                large
                @click="pickIcon(icono)"
              >
                <v-icon>{{ icono }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!valid"
          class="green white--text"
          @click="submit"
          :loading="loading"
          rounded
          >Actualizar</v-btn
        >
        <v-btn text class="accent--text" color="white" @click="close"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import IconJson from "@/icons.json"
import ColorMenu from '@/components/categorias/ColorMenu'

export default {
  name: "EditCategory",
  props: {
    categoria: Object,
  },
  components: {
    ColorMenu,
  },

  data() {
    return {
      valid: false,
      nombrecat: "",
      colore: "",
      icone: "",
      state: "active",
      id: "",
      inputRules: [
        (v) => !!v || "Este Campo es requerido",
        (v) => v.length >= 3 || "Minimo 3 caracteres",
      ],
      requiredRule: [(v) => !!v || "Este Campo es requerido"],
      loading: false,
      dialog: false,
      errorCatName: "",      
      myIcons: IconJson,
      errorCatColor: ""
    }
  },

  computed: {
    ...mapGetters(["categorias"]),
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        /* let user = this.$store.getters.authUser;
        if (typeof user.name === "undefined") {
          user = JSON.parse(user)
        } */

        var categoryData = {
          nombrecat: this.nombrecat,
          icon: this.icone,
          color: this.colore,
          state: 'active',
          id: this.id
        }

        this.$store
          .dispatch("update_category", categoryData)
          .then((response) => {
            this.loading = false
            this.dialog = false
            this.$emit("categoryUpdated", categoryData)
          })
          .catch((err) => {
              console.log(err)            
          })
      }
    },

    selecionarIconoColor(iconoColor) {
      this.icone = iconoColor.icono
      this.colore = iconoColor.color
    },

    close() {
      this.dialog = false
    },

    pickIcon(ico) {
      this.valid = true
      this.icone = ico
    },

    saveColor(color){
      this.valid = true
      this.colore = color
    }

  },

  watch: {
    nombrecat(newValue, oldValue) {
      /* const cate = this.categorias.find(
        (element) => element.nombrecat == newValue
      )
      if (cate !== undefined) {
        this.valid = false
        this.errorCatName = `Nombre de categoria ${this.nombrecat} ya existe`
      } else {
        this.valid = true;
        this.errorCatName = ""
      } */
    },
  },

  created () {
    //this.$root.$on('setDataCategory', (data) => {
      this.nombrecat = this.categoria.nombrecat
      this.colore = this.categoria.color
      this.icone = this.categoria.icon
      this.state = this.categoria.state
      this.id = this.categoria.id
	  //})
  },
}
</script>

<style scoped>
</style>
