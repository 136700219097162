<template>
  <v-container>
    <div class="gradient">
      <v-card>
        <v-toolbar
          color="accent"
          dark
          src="@/assets/images/v1.jpg"
          height="100px"
          class="mb-2"
        >
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          <v-toolbar-title class="title">Salidas</v-toolbar-title>
          <div class="ml-4 subtitle-1">orden: ultimo primero</div>
          <v-spacer></v-spacer>
          <NuevaSalida
            @salidaAdded1="salidaAgregada"
            :productos="productos"
          />
        </v-toolbar>
        <div class="text-center graysuave py-2">
            <v-btn small rounded color="verde" @click="volver">Volver</v-btn>
        </div>
        <v-divider></v-divider>
          <v-sheet v-if="!salidas"
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="px-3 pt-3 pb-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="800"
              type="table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-sheet>

          <Salida v-else v-for="(salida, index) in salidasCounter" :key="salida.id"
            :salida="salida" 
            :index="index" :contador = "salida.counter"
          />

        <div class="text-center py-2 graysuave" v-if="pagination!=null">
          <v-btn small class="mx-2 py-2" :disabled="prevDisabled" @click="previo">
            <v-icon small large>chevron_left</v-icon>
          </v-btn>
          <v-btn small class="mx-2 py-2">
            {{ pagination.actual_page+1 }}
          </v-btn>
          <v-btn small class="mx-2 py-2" :disabled="nextDisabled" @click="siguiente">
            <v-icon small large>chevron_right</v-icon>
          </v-btn>
        </div>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        :timeout="6000"
        top
        color="green"
        class="title"
      >
        <span>{{ mensaje }}</span>
        <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
      </v-snackbar>
<!--       <pre>
          {{ pagination }}
      </pre> -->
<!--       <pre>
        {{salidas}}
      </pre> -->
    </div>
  </v-container>
</template>
<script>
import Salida from "@/components/inventario/Salida.vue";
import NuevaSalida from "@/components/inventario/NuevaSalida.vue";

export default {
  inject: ["theme"],
  name: "SalidasList",
  components: { Salida, NuevaSalida },
  props: {},
  data() {
    return {
      productos: [],
      salidas: [],
      snackbar: false,
      iduser: 0,
      mensaje: "",
      paginas: [],
      page: 0,
      pagination: null,
      prevDisabled: true,
      nextDisabled: true,
      pages: 10,
      contador: 1,
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Link 1',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ]
    };
  },

  methods: {
    fetchSalidas() {
      this.salidas = null
      this.$store
        .dispatch("retrieve_salidas", 
            { 
              idu: this.iduser ,
              pages: this.pages,
              page: this.page
            })
        .then((response) => {
          this.salidas = response.result.data;
          //console.log(this.salidas)
          this.pagination = {
            first_page: response.result.pagination.first_page,
            actual_page: response.result.pagination.actual_page,
            next_page: response.result.pagination.next_page,
            total: response.result.pagination.total,
            pre_page: response.result.pagination.pre_page,
            pages: response.result.pagination.pages,
            last_page: response.result.pagination.last_page,
          };
        })
        .catch((err) => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchSalidas();
          }
        });
    },

    salidaAgregada(salida) {
      this.salidas.push(salida);

      var found = this.productos.find((obj) => obj.id == salida.productos_id);

      if (found != undefined) {
        found.salida =
          Number(found.salida) + Number(salida.cantidad_salida);
        found.saldo = Number(found.saldo) + Number(salida.cantidad_salida);
        this.productos[found.index] = found;
      }

      this.mensaje = "Excelente, Salida realizada";
      this.snackbar = true;
    },

    fetchProductos() {      
      this.$store
        .dispatch("retrieve_productos", { idu: this.iduser })
        .then((response) => {
          this.productos = response;
        })
        .catch((err) => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchProductos();
          }
        });
    },

    gotoSalidas() {
      this.router.push("salidas");
    },

    crearPaginas() {
      for (var x = 1; x <= this.lastpage; x = x + 1) {
        if (x === 1) {
          this.paginas.push({ ubicacion: x, clase: "bg-success" });
        } else {
          this.paginas.push({ ubicacion: x, clase: "" });
        }
      }
    },

    siguiente() {
      this.page = Number(this.pagination.actual_page+1)
      this.fetchSalidas()
    },

    previo(){
      this.page = Number(this.pagination.actual_page-1)
      if(this.page>=0){
        this.fetchSalidas()
      }
    },

    volver(){
      this.$router.go(-1)
    }

  },

  computed: {
    salidasCounter: function(){      
      var counter = Number(this.pagination.actual_page*this.pages +1);
      var res = this.salidas.map( function(salida){
          salida.counter = counter
          counter ++
          return salida
      })
      this.contador = counter
      return res
    }
  },

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }
    this.iduser = user.idu;

    this.fetchProductos();
    this.fetchSalidas();
    this.crearPaginas();
  },

  watch: {
    "pagination.pre_page": function(value, oldVal) {
      if (value === null) {
        this.prevDisabled = true;
      } else {
        this.prevDisabled = false;
      }
    },
    "pagination.next_page": function(value, oldVal) {
      if(value === null){
        this.nextDisabled = true
      } else {        
        this.nextDisabled = false
      }
    },

    
  },
};
</script>

<style scoped></style>
