<template>
    <div>
        <v-row no-gutters class="pb-2 pt-2 text-center">
            <v-col sm="1" md="1">
                <div class="py-4 font-weight-light">
                    <template v-if="contador!=null">
                        {{contador}}.-
                    </template>
                    <template v-else>
                        {{index+1}}.-
                    </template>
                </div>
            </v-col>
            <v-col sm="1" md="1" class="pl-2 pb-2 pt-2">
                <v-avatar color="secondary" size="30" >
                    <img v-if="entrada.picture!=null" :src="entrada.picture" :alt="entrada.name" >
                    <img v-else src="@/assets/images/mf.png" :alt="entrada.name" >
                </v-avatar>                
            </v-col>
            <v-col sm="3" md="2" class="px-3 pt-3 ">
                <div class="font-weight-thin text-left">{{entrada.name}}</div>
            </v-col>

            <v-col sm="2" md="2">
                <div class="font-weight-thin">Costo</div>
                <v-chip color="verde" small>
                    <strong v-html="entrada.costo"></strong>
                </v-chip>
            </v-col>

            <v-col sm="3" md="3">
                <div class="font-weight-thin">Fecha</div>
                <v-chip class="font-weight-thin" x-small color="latte" dark>
                    <strong v-html="entrada.fecha_entrada"></strong>
                </v-chip>
            </v-col>
            <v-col sm="2" md="2">
                <div class="font-weight-thin">Canti</div>
                <v-chip color="sky" small>
                    <strong v-html="entrada.cantidad_entrada"></strong>
                </v-chip>
            </v-col>
        </v-row>
        <v-divider></v-divider> 
    </div>
</template>

<script>
export default {
    name: 'Entrada',
    props: {
        entrada: Object,
        index: Number,
        contador: Number
    },
    data() {
        return {
            contadorx: 0
        }
    },

    created(){
        //this.contadorx = this.contador +1
    }
}
</script>

<style>

</style>