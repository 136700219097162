<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="550" eager>
      <template v-slot:activator="{ on }">
        <v-btn rounded v-on="on" large>
            <v-icon left>mdi-calendar</v-icon>{{hoy}}
        </v-btn>
      </template>
      <v-card dark color="skim">
        <v-card-title class="headline">
            Filtrar entre Fechas:
        </v-card-title>
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="6">                    
                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false"
                          :return-value.sync="fecha1" transition="scale-transition" offset-y  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="fecha1"
                                label="Fecha Inicial"
                                prepend-icon="mdi-calendar"
                                readonly
                                filled rounded dense
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha1" locale="es" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.menu1.save(fecha1)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                          :return-value.sync="fecha2" transition="scale-transition" offset-y  min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="fecha2"
                                label="Fecha Final"
                                prepend-icon="mdi-calendar"
                                readonly
                                filled rounded dense
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha2" locale="es" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.menu2.save(fecha2)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-alert v-if="errorMessage!=''" type="warning" class="skim--text">
                            {{errorMessage}}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="error darken-1" rounded @click="dialog = false">Cerrar</v-btn>
            <v-btn color="green darken-1" rounded @click="filtrar">Filtrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'Filtro',
    props: {
      hoy: String      
    },

    data () {
      return {
        dialog: false,
        menu1: false,
        menu2: false,
        fecha1: '',
        fecha2: '',
        //loading: false,
        errorMessage: ''
      }
    },
    methods:{        
        filtrar(){
            //this.loading = true
            let date1 = new Date(this.fecha1)
            let date2 = new Date(this.fecha2)
            if(date1.getTime()>date2.getTime()){
                this.errorMessage = 'Fecha 2 no puede ser menor a Fecha1'
            } else {
                this.errorMessage = ''
                this.$emit('aplicarFiltro', {
                    date1: this.fecha1,
                    date2: this.fecha2
                })
                this.dialog = false
            }
            //this.loading = false                
        }
    },
    created(){      
        let date = new Date(), y = date.getFullYear(), m = date.getMonth();
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);

        let fmes = firstDay.getMonth() + 1
        if(fmes<10){
            fmes = '0'+fmes
        }
        let lmes = lastDay.getMonth() + 1
        if(lmes<10){
            lmes = '0'+lmes
        }

        let fday = firstDay.getDate()
        if(fday<10){
            fday = '0'+fday
        }
        let lday = lastDay.getDate()
        if(lday<10){
            lday = '0'+lday
        }

        this.fecha1 = firstDay.getFullYear()+'-'+(fmes)+'-'+fday
        this.fecha2 = lastDay.getFullYear()+'-'+(lmes)+'-'+lday

    }
  }
</script>