<template>
  <v-stepper-content step="2">
    <v-card>
      <v-card-text>
        <v-select
          v-model="recetax"
          :items="recetas"
          item-text="descripcion"
          return-object
          label="Recetas"
          single-line
          required
          v-on:change="changeRecetas"
          filled
          rounded
          clearable
          chips
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn color="verde" class="white--text" rounded @click="goto" :disabled="!valid">Continuar</v-btn>
        <v-btn text rounded @click="goback">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper-content>
</template>

<script>
export default {
  name: "Paso2",
  props: {
    usuario: Object,
    plato: Object
  },

  data() {
    return {
      recetas: [],
      recetax: null,
      valid: false
    };
  },

  methods: {
    goto() {
      this.$emit("continuar", this.recetax);
    },

    fetchRecetas() {
      if (this.plato != null) {
        this.$store
          .dispatch("retrieve_recetas_plato", {
            id: this.plato.id
          })
          .then(response => {
            this.recetas = response;
            /*           this.recetas.sort((a, b) =>
                  a["producto"] < b["producto"] ? -1 : 1
                ); */
          })
          .catch(err => {
            console.log(err);
            if (err == 401) {
              this.$store.dispatch("refesh_token");
              this.fetchRecetas();
            }
          });
      }
    },

    changeRecetas() {},

    goback() {
      //this.recetas = [];
      this.recetax = null;
      this.$emit("volver", 1);
    }
  },

  created() {},

  watch: {
    plato(newValue, oldValue) {
      this.fetchRecetas();
    },

    recetax(newValue, oldValue) {
      if (newValue == null) {
        this.valid = false;
      } else {
        this.valid = true;
      }
    }
  }
};
</script>

<style scoped>
</style>