<template>
  <div class="gradient">
    <v-row justify="center" class="mt ml-0 mr-0">
      <v-snackbar v-model="verMensaje" color="darkverde" :timeout="3000" top>{{ resultMesage }}</v-snackbar>

      <v-container>
        <v-card>
          <v-toolbar color="accent" dark src="@/assets/images/h1.jpg" height="100px">
            <v-toolbar-title>Ventas</v-toolbar-title>
            <v-spacer></v-spacer>
            <Filtro @aplicarFiltro="applyFilter" :hoy="hoy" v-if="usuario.rl==1" />
          </v-toolbar>
          <v-divider></v-divider>
          <v-sheet
            v-if="!ventas"
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="px-3 pt-3 pb-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="90vh"
              type="table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-sheet>

          <template v-else style="height: 90vh">
            <div v-for=" (venta) in ventasCounter" :key="venta.id"  >
              <v-row no-gutters class="pb-2 pt-2">
                <v-col cols="3" sm="1" md="1" class="pl-2">
                  <div class="py-4 font-weight-light">{{ venta.counter }}.-</div>
                </v-col>
                <v-col cols="3" sm="2" md="2" class="pl-1 pb-2 text-left">
                  <div class="font-weight-thin">Fecha</div>
                  <div class="font-weight-light">
                    <v-chip color="secondary" class="px-1 skim--text">{{venta.fecha_venta}}</v-chip>
                  </div>
                </v-col>
                <v-col cols="3" sm="1" md="1" class="px-1 text-center">
                  <div class="font-weight-thin">Total</div>
                  <v-chip color="green">
                    <strong v-html="venta.total"></strong>
                  </v-chip>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="px-1">
                  <div class="font-weight-thin">Utilidad</div>
                  <v-chip color="verde">
                    <strong v-html="venta.utilidad"></strong>
                  </v-chip>
                </v-col>

                <v-col cols="4" sm="1" md="1" class="px-1 text-center">
                  <div class="font-weight-thin">T.Pago</div>
                  <v-chip color="secondary" dark x-small class="skim--text">
                    <strong v-html="venta.tipopago"></strong>
                  </v-chip>
                </v-col>

                <v-col cols="4" sm="1" md="1" class="px-1 text-center">
                  <div class="font-weight-thin">Nit/Ci</div>
                  <div class="font-weight-light">
                    <strong v-html="venta.nitci" v-if="venta.nitci!=null"></strong>
                    <template v-else>--</template>
                  </div>
                </v-col>

                <v-col cols="4" sm="1" md="1" class="px-2 text-center">
                  <div class="font-weight-thin">Efectivo</div>
                  <div class="font-weight-light">
                    <strong v-html="venta.efectivo"></strong>
                  </div>
                </v-col>

                <v-col cols="4" sm="1" md="1" class="px-2 text-center">
                  <div class="font-weight-thin">Cambio</div>
                  <div class="font-weight-light">
                    <strong v-html="venta.cambio"></strong>
                  </div>
                </v-col>

                <v-col cols="4" sm="1" md="1" class="px-2 text-center">
                  <div class="font-weight-thin">Factura</div>
                  <div class="font-weight-light">
                    <strong v-html="venta.factura" v-if="venta.factura!=null"></strong>
                    <template v-else>--</template>
                  </div>
                </v-col>

                <v-col cols="4" sm="2" md="2" class="px-2 py-4 centro">
                    <Detalle :venta="venta" />
                    <AnularVenta
                      @borrarItem="deleteItem"
                      :item="venta"
                      :usuario="usuario"
                      v-if="usuario.rl==1"
                    />                  
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
          </template>


          <div class="text-center py-2 graysuave" v-if="pagination!=null">
            <v-btn small class="mx-2 py-2" :disabled="prevDisabled" @click="previo">
              <v-icon small large>mdi-arrow-left-bold</v-icon>
            </v-btn>
            <v-btn small class="mx-2 py-2">{{ pagination.actual_page+1 }}</v-btn>
            <v-btn small class="mx-2 py-2" :disabled="nextDisabled" @click="siguiente">
              <v-icon small large>mdi-arrow-right-bold</v-icon>
            </v-btn>
          </div>

          <v-spacer></v-spacer>
        </v-card>

        <v-row class="pb-11">
          <v-col cols="12" sm="4" md="12">
            <v-card class="pa-2" outlined tile>
              <v-chip class="ma-2" color="darkverde" text-color="white" x-large>
                TOTAL:{{total}} &nbsp;&nbsp;
                <span class="subtitle-2 ml-2">(Bs)</span>
              </v-chip>
              <v-chip class="ma-2 float-right" color="verde" text-color="white" x-large>
                UTILIDAD: {{utilidad}} &nbsp;&nbsp;
                <span class="subtitle-2 ml-2">(Bs)</span>
              </v-chip>
            </v-card>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-bottom-navigation
          class="pt-1"
          v-model="bottomNav"
          hide-on-scroll
          absolute
          horizontal
          v-if="usuario.rl==1"
        >
          <v-btn value="recent" @click="donwloadPDF">
            <span>Imprimir</span>
            <v-icon>mdi-printer</v-icon>
          </v-btn>

          <v-btn>
            <download-excel
              class="excel"
              :data="ventas"
              :fields="json_fields"
              worksheet="Ingresos"
              name="ventas.xls"
            >
              <v-icon color="green">mdi-microsoft-excel</v-icon>Excel
            </download-excel>
          </v-btn>
        </v-bottom-navigation>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import Filtro from "@/components/ventas/Filtro.vue"
import AnularVenta from "@/components/ventas/AnularVenta.vue"
import Detalle from "@/components/ventas/Detalle"
import jsPDF from "jspdf"
//import jspdfAutotable from 'jspdf-autotable'
import "jspdf-autotable"

import Vue from "vue"
import JsonExcel from "vue-json-excel"

Vue.component("downloadExcel", JsonExcel)

export default {
  inject: ["theme"],
  name: "Ventas",
  components: {
    Filtro,
    Detalle,
    AnularVenta
  },
  data: () => ({
    resultMesage: "",
    verMensaje: false,
    bottomNav: "recent",
    menu: false,
    modal: false,
    menu2: false,

    toggle_one: 0,

    text: "center",
    icon: "justify",

    date1: "",
    date2: "",

    json_fields: {
      "Fecha Venta": "fecha_venta",
      Total: "total",
      "Nit/Ci": "nitci",
      "Tipo de Pago": "tipopago",
      Efectivo: "efectivo",
      Cambio: "cambio",
      Factura: "nfactura",
      Estado: "estadoventa"
    },

    headers: [
      { text: "Fecha", value: "fecha_venta" },
      { text: "Total", value: "total" },
      { text: "Utilidad", value: "utilidad" },
      { text: "Nit/Ci", value: "nitci" },
      { text: "T.Pago", value: "tipopago" },
      { text: "Efectivo", value: "efectivo" },
      { text: "Cambio", value: "cambio" },
      { text: "Factura", value: "nfactura" },
      { text: "Estado", value: "estadoventa" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    ventas: null,
    monedas: ["Bolivianos", "Dolares"],
    total: 0,
    hoy: "",
    utilidad: 0,
    usuario: null,
    page: 0,
    pages: 30,
    pagination: null,
    prevDisabled: true,
    nextDisabled: true,
    total: 0,
    utilidad: 0
  }),

  computed: {
    ventasCounter: function() {
      
      var counter = Number(this.pagination.actual_page*this.pages +1);
      var res = this.ventas.map(function(venta) {
        venta.counter = counter;
        counter++;
        return venta;
      });

      return res;
    }
  },

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }

    this.usuario = user;

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    this.date1 = yyyy + "-" + mm + "-" + dd;
    this.date2 = yyyy + "-" + mm + "-" + dd;
    this.hoy = this.date1;
    this.fetchVentas();
  },

  methods: {
    donwloadPDF() {
      let pdf = new jsPDF();

      let col = [
        "Fecha",
        "Total",
        "Nit/Ci",
        "Tipo de Pago",
        "Efectivo",
        "Cambio",
        "Factura",
        "Estado"
      ];
      let rows = [];

      this.ventas.forEach(function(venta, i) {
        var temp = [
          venta.fecha_venta,
          venta.total,
          venta.nitci,
          venta.tipopago,
          venta.efectivo,
          venta.cambio,
          venta.nfactura,
          venta.estadoventa
        ];
        rows.push(temp);
      });
      pdf.text(this.usuario.name, 10, 10);
      pdf.autoTable(col, rows);
      pdf.text("Total: " + this.total, 100, 10);
      pdf.save("ventas.pdf");
    },

    applyFilter(fechas) {
      //console.log('applyFilter')
      this.date1 = fechas.date1;
      this.date2 = fechas.date2;
      if (this.date1 == this.date2) {
        this.hoy = this.date1;
      } else {
        this.hoy = this.date1 + " al " + this.date2;
      }

      this.pagination = null
      this.page = 0
      this.fetchVentas();
    },

    fetchVentas() {
      this.ventas = null;
      this.total = 0;
      let formData = {
        date1: this.date1,
        date2: this.date2,
        pages: this.pages,
        page: this.page
      }
      //this.$store.dispatch('retrieveIngresos', formData );
      //this.ventas = this.$store.getters.ventas;

      this.$store
        .dispatch("retrieve_ventas", formData)
        .then(response => {
          this.ventas = response.result.data;
          this.pagination = {
            first_page: response.result.pagination.first_page,
            actual_page: response.result.pagination.actual_page,
            next_page: response.result.pagination.next_page,
            total: response.result.pagination.total,
            pre_page: response.result.pagination.pre_page,
            pages: response.result.pagination.pages,
            last_page: response.result.pagination.last_page
          }

          this.total = response.result.calculos.total
          this.utilidad = response.result.calculos.utilidad

        })
        .catch(err => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchVentas();
          }
        });
    },

    changeRoute(a) {
      this.editedItem.categorias_id = a.id;
      this.editedItem.nfactura = a.nfactura;
    },

    deleteItem(item) {
      const index = this.ventas.indexOf(item);
      this.ventas.splice(index, 1);
      this.resultMesage = "Venta anulada con exito";
      this.verMensaje = true;
    },

    siguiente() {
      this.page = Number(this.pagination.actual_page + 1);
      this.fetchVentas();
    },

    previo() {
      this.page = Number(this.pagination.actual_page - 1);
      if (this.page >= 0) {
        this.fetchVentas();
      }
    },

  },

  watch: {
    "pagination.pre_page": function(value, oldVal) {
      if (value === null) {
        this.prevDisabled = true;
      } else {
        this.prevDisabled = false;
      }
    },
    "pagination.next_page": function(value, oldVal) {
      if (value === null) {
        this.nextDisabled = true;
      } else {
        this.nextDisabled = false;
      }
    }
  }
}
</script>

<style scoped>
  .centro{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
