<template>
  <v-container fluid class="gradient">
    <v-layout>
      <v-flex xs12>
        <v-card>
          <v-toolbar color="accent" dark src="@/assets/images/h1.jpg" height="100px">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title>Productos</v-toolbar-title>
            <v-spacer></v-spacer>
            <NuevoProducto @productoAdded="productoAgregado" :grupos="grupos" />
          </v-toolbar>

          <v-layout row justify-start class="py-5">
            <v-btn class="pl-5" small text color="skim" @click="sortBy('id')" slot="activator">
              <v-icon small left>mdi-folder</v-icon>
              <span class="caption text-lowercase">Ordenar por ID</span>
            </v-btn>
            <v-btn small text color="skim" @click="sortBy('name')" slot="activator">
              <v-icon small left>mdi-folder</v-icon>
              <span class="caption text-lowercase">Ordenar por Nombre</span>
            </v-btn>
          </v-layout>

          <v-divider></v-divider>

          <v-sheet
            v-if="!productos"
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="px-3 pt-3 pb-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="800"
              type="table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-sheet>

          <div v-else class="scroll">

            <div v-for="(producto, index) in productos" :key="producto.id">
              <v-row no-gutters class="pb-2 pt-2">
                <v-col cols="1" sm="1" md="1" class="text-center">
                  <div class="py-4 font-weight-light">{{ index + 1 }}.-</div>
                </v-col>
                <v-col cols="3" sm="1" md="1" class="pl-2 pb-2 pt-2 text-left">
                  <v-avatar color="secondary" size="40" class="px-0 py-0">
                    <img
                      v-if="producto.picture == null || producto.picture==''"
                      src="@/assets/images/mf.png"
                      :alt="producto.name"
                    />
                    <img v-else :src="producto.picture" :alt="producto.name" />
                  </v-avatar>
                </v-col>
                <v-col cols="7" sm="2" md="2" class="text-left">
                  <div class="text-left font-weight-light">{{ producto.name }}</div>
                  <v-chip small color="green" class="font-weight-light">
                    <strong v-html="producto.gname"></strong>
                  </v-chip>
                </v-col>

                <v-col cols="4" sm="2" md="2" class="px-1 text-center">
                  <div class="font-weight-thin">Entradas</div>
                  <v-chip color="sky">
                    <strong v-html="producto.entrada"></strong>
                  </v-chip>
                </v-col>

                <v-col cols="4" sm="2" md="1" class="px-1 text-center">
                  <div class="font-weight-thin">Salidas</div>
                  <v-chip color="primary">
                    <strong v-html="producto.salida"></strong>
                  </v-chip>
                </v-col>
                <v-col cols="4" sm="1" md="2" class="px-1 text-center">
                  <div class="font-weight-thin">Medida</div>
                  <v-chip color="skim" dark x-small>
                    <strong v-html="producto.unids"></strong>
                  </v-chip>
                </v-col>
                <v-col cols="9" sm="2" md="2" class="px-2 text-center">
                  <div class="font-weight-thin">Saldo</div>
                  <v-progress-linear
                    :color="calcularColor(producto.saldo)"
                    :value="producto.saldo"
                    height="15"
                    reactive
                  >
                    <strong class="ml-2">{{ producto.saldo }}</strong>
                  </v-progress-linear>
                </v-col>
                <v-col cols="3" sm="1" md="1" class="px-2 text-center">
                  <EditarProducto :producto="producto" :grupos="grupos"/>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import NuevoProducto from "@/components/inventario/NuevoProducto.vue";
import EditarProducto from "@/components/inventario/EditarProducto.vue";
//import Producto from "@/components/inventario/Producto.vue";

export default {
  inject: ["theme"],
  components: {
    NuevoProducto,
    EditarProducto
  },
  props: {
    productos: Array
  },
  data() {
    return {
      snackbar: false,
      iduser: 0,
      mensaje: "",
      grupos: []
    };
  },

  methods: {
    sortBy(param) {
      //console.log('sort: '+param)
      this.productos.sort((a, b) => (a[param] < b[param] ? -1 : 1));
    },

    productoAgregado(producto) {
      this.mensaje = "Increible! Agregaste un nuevo Producto.";
      this.productos.push(producto);
      this.snackbar = true;
    },

    productoActualizado(producto) {
      this.mensaje = "Excelente!... Modificaste un Producto.";
      this.snackbar = true;
    },

    fetchProductos() {
      this.$store
        .dispatch("retrieve_productos", { idu: this.iduser })
        .then(response => {
          this.productos = response;
        })
        .catch(err => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchProductos();
          }
        });
    },

    toggle(event, idlat) {
      this.$store
        .dispatch("update_estado_producto", {
          estadoproducto: event,
          idlat: idlat
        })
        .then(response => {
          //console.log(response)
        })
        .catch(errore => {
          console.log(errore);
        });
    },

    initialize1() {
      this.$store
        .dispatch("retrieveCategorias", { idu: this.iduser })
        .then(response => {
          this.grupos = response;
        })
        .catch(err => {
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.initialize1();
          }
        });
    },

    calcularColor(saldo) {
      if (saldo == 0) return "red";
      if (saldo <= 30) return "yellow";
      if (saldo >= 30) return "green";
    },

  },

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }
    this.iduser = user.idu;

    this.$store
      .dispatch("retrieve_grupos", { idu: this.iduser })
      .then(response => {
        this.grupos = response;
      })
      .catch(err => {
        if (err == 401) {
          this.$store.dispatch("refesh_token");
          this.initialize1();
        }
      });

    //this.fetchProductos()
    /*     this.$root.$on("productoAdded", (unProducto) => {
      this.productoAgregado(unProducto);
    }); */
  },

  computed: {
    getproductos() {
      this.productos = this.$store.getters.productos;
      return this.productos;
    }
  }
};
</script>

<style scoped>
.producto.active {
  border-left: 4px solid #83ebb6;
  margin-left: 0px;
}

.producto.inactive {
  border-left: 4px solid #f83e70;
  margin-left: 0px;
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #83ebb6;
}

.theme--light.v-size--small.chip.inactive {
  background: #f83e70;
}

.scroll {
  overflow-y: auto;
  height: 400px;
}
</style>