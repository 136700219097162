import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Administrator from '@/views/Administrator'
import Category from '@/views/Category'
import Login from '@/views/Login'
import Profile from '@/views/Profile'
import Platos from '@/views/Platos'
import Venta from '@/views/Venta'
import Ventas from '@/views/Ventas'
//import Test from '@/views/Test'
import Negocio from '@/views/Negocio'
import Recetas from '@/views/admin/Recetas'
import Inventario from '@/views/admin/Inventario'
import EntradasList from '@/components/inventario/EntradasList'
import SalidasList from '@/components/inventario/SalidasList'
import Cotizador from '@/views/Cotizador'
import Providers from '@/views/admin/Providers'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'administrator',
      component: Administrator,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/categorias',
      name: 'categorias',
      component: Category,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/menu',
      name: 'menu',
      component: Platos,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/providers',
      name: 'providers',
      component: Providers,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/venta',
      name: 'venta',
      component: Venta,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/ventas',
      name: 'ventas',
      component: Ventas,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/negocio',
      name: 'negocio',
      component: Negocio,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/recetas',
      name: 'recetas',
      component: Recetas,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/inventario',
      name: 'inventario',
      component: Inventario,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/inventario/entradas',
      name: 'entradas',
      component: EntradasList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/inventario/salidas',
      name: 'salidas',
      component: SalidasList,
      meta: {
        requiresAuth: true
      }
    },
    
    {
      path: '/cotizador',
      name: 'Cotizador',
      component: Cotizador,
      meta: {
        requiresAuth: true
      }
    }


  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router