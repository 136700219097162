<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="550" eager>
      <template v-slot:activator="{ on }">
        <v-btn small icon v-on="on">
            <v-icon color="errore">mdi-delete</v-icon>
        </v-btn>
      </template>
      <v-card dark class="skim">
        <v-card-title class="headline">            
        </v-card-title>
        <v-card-text>
            <v-alert dark type="warning" dense icon="warning" class="skim--text text-center title">
                {{ mensaje }}
            </v-alert>
<!--             <pre>
              {{usuario}}
            </pre> -->
        </v-card-text>

        <v-card dark color="skim" v-if="cuenta">
            <v-card-title>
              <h4>Cuenta Administrador:</h4>
            </v-card-title>
            
              <v-form class="px-6" ref="form" v-model="valid" :lazy-validation="lazy" >
                  <v-container  fluid>
                    <v-row >
                      <v-col cols="12" sm="12" md="12" >
                        <v-text-field v-model="login" label="Login" autocomplete="off" class= "subtitle-2" filled rounded
                          dense >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="clave" type="password" label="Password" dense autocomplete="off" filled rounded >
                        </v-text-field>
                      </v-col>

                      <v-card-actions>
                          <v-btn class="error" color="white" text @click="close" rounded>
                              Cancelar
                          </v-btn>
                          <v-btn :disabled="!valid"  @click="submit" color="green" dark :loading="loading" rounded >
                              Verificar 
                          </v-btn>
                      </v-card-actions>

                    </v-row>
                </v-container>
              </v-form>
            
          </v-card>

        <v-card-actions v-if="!cuenta">
          <v-spacer></v-spacer>
          <template v-if="valido">
            <v-btn color="green darken-1" text @click="close">No</v-btn>
            <v-btn color="error darken-1" text @click="cuenta=true" >Si</v-btn>
          </template>
          <template v-else>
            <v-btn color="green darken-1" text @click="close">Cerrar</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'AnularVenta',
    props:{
        item: Object,
        usuario: Object
    },
    data () {
      return {
        dialog: false,
        loading: false,
        mensaje: '¿Esta seguro de anular la venta?',
        valido: true,
        cuenta: false,
        lazy: false,
        valid: false,

        login: '',
        clave: ''
      }
    },
    methods:{
        anular(){
            this.$store.dispatch("update_estado_venta", 
                {
                  'estadoventa': 'anulado',
                  'id': this.item.id
                }
            ).then(respuesta => {
                  this.loading = false
                  this.dialog = false
                  this.$emit("borrarItem", this.item)
              }).catch(errore => { 
                console.log(errore)
                this.mensaje = 'Error intente más tarde'
              })
        },

        close(){
          this.cuenta = false
          this.dialog = false
        },

        submit(){
          if (this.$refs.form.validate()) {

            this.$store.dispatch("verify_user", 
                {
                  'email': this.login,
                  'password': this.clave,
                  'id': this.usuario.nid
                }
            ).then(response => {
                this.anular()
              }).catch(err => { 
                console.log(err) 
                this.mensaje = 'Invalido'
              })
          }
        }
    },

    created(){        
        this.login =  this.usuario.email

        var yearItem = parseInt(this.item.created_at)
        var yearActual = new Date().getFullYear()
        if(yearItem!=yearActual){
            this.mensaje = 'Imposible eliminar'
            this.valido = false
        } else {
          this.mensaje = '¿Esta seguro de anular la venta?'
          this.valido = true
        }
    }
  }
</script>