<template>
<v-container fluid class="gradient">

  <v-data-table :headers="headers" :items="recetas" sort-by="descripcion" class="elevation-1" locale="es"
    :items-per-page="itemsPerPage" >
    <template v-slot:top>
        <v-toolbar color="accent" dark src="@/assets/images/h1.jpg" height="100">
            <v-app-bar-nav-icon></v-app-bar-nav-icon>
            <v-toolbar-title >Mis Recetas</v-toolbar-title>
            <v-spacer></v-spacer>

            <NuevaReceta @recetaAdded="recetaAgregada" :platos = "platos" />

        </v-toolbar>
    </template>

    <template v-slot:item.action="{ item }">
      <v-btn-toggle v-model="toggle_one">
        <DetalleReceta :receta = "item" />
        <EditarReceta :platos = "platos" :receta= "item" :iduser="usuario.idu"  @recetaUpdated="actualizarReceta"/>        
        <v-btn small>
            <v-icon color="red">fas fa-trash-alt</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
    <template v-slot:item.nombreplato="{ item }">
      <v-chip color="verde" dark>{{ item.nombreplato }}</v-chip>
    </template>
    <template v-slot:no-data>
      <h1>Sin registros</h1>
    </template>
  </v-data-table>

  <v-spacer></v-spacer>

  <v-snackbar v-model="verMensaje" :timeout="4500" top color="green" class="title" >
    <span>{{resultMesage}}</span>
    <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
  </v-snackbar>   

</v-container>
</template>

<script>
import NuevaReceta from '@/components/recetas/NuevaReceta'
import EditarReceta from '@/components/recetas/EditarReceta'
import DetalleReceta from '@/components/recetas/DetalleReceta'

  export default {
    name: 'Recetas',
    components: {
        NuevaReceta, EditarReceta, DetalleReceta
    },
    data: () => ({

        toggle_one: 0,
        resultMesage: 'Excelente Creaste una Receta',
        verMensaje: false,
        bottomNav: 'recent',
        menu: false,
        modal: false,
        menu2: false,
        itemsPerPage: 25,

        text: 'center',
        icon: 'justify',

        headers: [
            { text: 'Plato', value: 'nombreplato' },
            { text: 'Fecha', value: 'fecha' },
            { text: 'NPax', value: 'npax' },
            { text: 'C.Var.Unit', value: 'costovariableunitario' },
            { text: 'M.Error', value: 'margenerror' },
            { text: 'C.Total Unit', value: 'costo_totalunitario' },
            { text: 'C. Unit', value: 'costo_unitario' },
            { text: 'Utilidad', value: 'utilidad' },
            { text: 'Precio Venta', value: 'precioventa' },
            { text: 'Precio Fact', value: 'preciofacturado' },
            { text: 'Acciones', value: 'action', sortable: false },
        ],

        recetas: [],

        editedIndex: -1,

        usuario: null,

        platos: []

    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Receta' : 'Editar Receta'
      },

    },

    watch: {
        dialog (val) {
          val || this.close()
        }
    },

    created () {
        let user = this.$store.getters.authUser
        if (typeof user.name === "undefined") {
            user = JSON.parse(user);
        }
        this.usuario = user
        this.recetas = []

        this.fetchRecetas()
        this.fetchPlatos()
    },

    methods: {

      fetchRecetas(){
        this.$store.dispatch("retrieve_recetas", {idu: this.usuario.idu}).then( response => {
            this.recetas = response
          }).catch(err => {
              console.log(err);
              if(err==401){
                this.$store.dispatch('refesh_token')
                this.fetchRecetas()
              }
          })
      },

      fetchPlatos(){
          this.$store.dispatch("retrieve_platos", {idu: this.usuario.idu}).then( response => {
              this.platos = response
          }).catch(err => {
              if(err==401){
                  this.$store.dispatch('refesh_token')
                  this.fetchPlatos()
              }
          })
      },

      recetaAgregada(nuevaReceta){
          this.recetas.push(nuevaReceta)
          this.verMensaje = true
      },

      deleteItem (item) {
        const index = this.recetas.indexOf(item)
        this.recetas.splice(index, 1)
        this.resultMesage = 'Receta anulada con exito'
        this.verMensaje = true
      },

      actualizarReceta(){
        this.resultMesage = 'Receta Actualizada'
        this.verMensaje = true
      }

    },
  }
</script>

<style scoped>

</style>