<template>
  <v-stepper-content step="3">
    <v-row v-if="receta!=null" no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-card-title>{{receta.descripcion}}</v-card-title>
          <v-card-subtitle>
            <span class="subtitle-1 skim--text">Costo Unitario (Bs):</span>
            <v-chip class="subtitle-2 ml-2" dark color="skim">{{receta.costo_unitario}}</v-chip>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="npax"
                  label="#Platos"
                  type="number"
                  :min="0"
                  autocomplete="off"
                  :rules="[v => !!v || '#Pax es requerido']"
                  filled
                  rounded
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" class="py-2 px-4">
                <span class="subtitle-1 skim--text">Costo Estimado (Bs):</span>
                <v-chip
                  class="title ml-2 black--text"
                  dark
                  color="verde"
                >{{ (receta.costo_unitario*npax).toFixed(1) }}</v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="verde"
              class="white--text"
              :disabled="!valid"
              :loading="loading"
              rounded
              @click="next"
            >
              Insumos             
            </v-btn>
            <v-btn text rounded @click="goback">Volver</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-stepper-content>
</template>

<script>
export default {
  name: "Paso3",
  props: {
    receta: Object
  },
  data() {
    return {
      loading: false,
      valid: false,
      npax: 0
    };
  },

  methods: {
    goback() {
      this.$emit("continuar");
    },
    next() {
      this.$emit("detalle", this.npax);
    }
  },

  watch: {
    npax(newValue, oldValue) {
      if (newValue > 0) {
        this.$emit("reset");
        this.valid = true;
      } else {
        this.valid = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>