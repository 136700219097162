<template>
  <!-- max-width="900px"  -->
  <v-dialog persistent v-model="dialog" max-width="980px">
    <template v-slot:activator="{ on }">
      <v-btn icon small v-on="on" class="primary">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card dark>
      <v-card-title class="py-1">
        <h3>Editar Plato</h3>
      </v-card-title>
      
      <v-layout justify-center wrap>
        <v-flex xs12 md6 sm12>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="nombreplato"
                    label="Nombre del Plato"
                    filled
                    rounded
                    dense
                    :rules="requiredRule"
                    :error-messages="errorPlatoName"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="precioventa"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Precio Venta"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="precioprepa"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Costo"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md12>
                  <v-select
                    class="mt-0"
                    clearable
                    filled
                    rounded
                    v-model="category"
                    :error-messages="errorCats"
                    :items="categories"
                    item-text="nombrecat"
                    
                    chips
                    
                    label="Categorias"
                    single-line
                    :rules="[v => !!v || 'Categoria es requerida']"
                    required
                    v-on:change="changeRoute"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>

        <v-flex xs12 md6 sm12>
          <v-img v-if="foto1!=null" :src="foto1" :alt="nombreplato" contain></v-img>
          <CropperImage 
            @imagenChanged="imagenCambiada" 
          />
        </v-flex>
      </v-layout>
      <v-card-actions>
          <v-btn
            :disabled="!valid"
            @click="submit"
            class="green white--text"
            :loading="loading"
            rounded
          >
            Guardar
          </v-btn>
          <v-btn class="skim--text" text @click="close">
            Cancelar
          </v-btn>
      </v-card-actions>
    </v-card>    
  </v-dialog>
</template>

<script>
//import { VueCropper } from "vue-cropper";
import CropperImage from "@/components/platos/CropperImage.vue"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "EditPlato",
  props: {
    plato: Object
  },
  components: {
    CropperImage
  },

  data() {
    return {
      valid: true,
      lazy: false,
      loading: false,
      dialog: false,

      errorPlatoName: "",
      state: "activo",

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      errorPlatoName: "",

      hasImage: false,
      image: null,
      vistaPrevia: true,
      type: "",
      verFotos: false,
      idu: 0,
      id: 0,
      errorCats: "",
      category: null,
      nombreplato: "",
      precioventa: 0,
      precioprepa: 0,
      estadoplato: false,
      estado_plato: "",
      foto1: ""
    }
  },

  methods: {
    ...mapActions(['updatePlatoImage']),
    submit(){
      if (this.$refs.form.validate()) {
        if (!this.valid) {
          this.loading = true
          this.updatePlato()
        }
      }
    },

    updatePlato() {
      this.$store
        .dispatch("update_plato", {
          nombreplato: this.nombreplato,
          precioventa: this.precioventa,
          precioprepa: this.precioprepa,
          category: this.category,
          id: this.plato.id
        })
        .then(response => {
          this.$emit("platoUpdated", this.plato)
          this.loading = false
          this.dialog = false
        })
        .catch(errore => {
          console.log(errore)
        })
    },

    close() {
      this.dialog = false
    },

    changeRoute(a) {
      //console.log(a)
      this.valid = true
      this.errorCats = ""
    },

    imgLoad(msg) {
      //console.log(msg)
    },

    imagenCambiada(imagen) {
      this.updatePlatoImage({
        foto: imagen,
        id: this.plato.id
      }).then(resp=>{
        this.$emit('platoUpdated')
      })
      this.foto1 = imagen
    }
  },

  computed: {
    ...mapGetters(['categories'])
  },

  created() {
    let user = this.$store.getters.authUser
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }
    this.idu = user.idu   
    this.foto1 = this.plato.foto1

    this.nombreplato = this.plato.nombreplato
    this.precioventa = this.plato.precioventa
    this.precioprepa = this.plato.precioprepa
    this.category = this.plato.category

    /* const url = new URL(this.plato.foto1);
    let path = url.pathname.split("/");
    path.pop(); // remove the last
    url.pathname = path.join("/")
    console.log(url) */

  }
}
</script>

<style scoped>
  input[type="file"] {
    display: none;
  }
</style>

