<template>
  <v-stepper-content step="1">
    <v-card>
      <v-card-text>
        <v-autocomplete
          :items="platos"
          :filter="customFilter"
          item-text="nombreplato"
          label="Platos"
          v-model="platox"
          return-object
          filled
          rounded
          clearable
          chips
          v-on:change="changePlato"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn color="verde" class="white--text" rounded @click="goto" :disabled="!valid">Continuar</v-btn>
        <v-btn text rounded @click="cancel">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper-content>
</template>

<script>
export default {
  name: "Paso1",
  props: {
    usuario: Object,
    plato: Object
  },

  created() {
    this.fetchPlatos();
  },

  data() {
    return {
      valid: false,
      platos: null,
      platox: null
    };
  },
  methods: {
    customFilter(item, queryText, itemText) {
      const textOne = item.nombreplato.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },

    fetchPlatos() {
      this.$store
        .dispatch("retrieve_platos", { idu: this.usuario.idu })
        .then(response => {
          this.platos = response;
        })
        .catch(err => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchPlatos();
          }
        });
    },

    changePlato() {
      //this.plato = this.platox
    },

    goto() {
      this.$emit("continuar", this.platox);
    },
    cancel() {
      this.platox = null;
      this.$emit("cancelar");
    }
  },

  watch: {
    platox(newValue, oldValue) {
      if (newValue == null) {
        this.valid = false;
      } else {
        this.valid = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>