<template>
  <div>
    <v-app-bar app clipped-right>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title class="gris--text">
       myFood
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <router-link to="/profile">
        <v-avatar>
          <img
            v-if="usuario.thumb==null || usuario.thumb==''"
            src="@/assets/images/user.png"
            :alt="usuario.nombres"
          />
          <img v-else :src="usuario.thumb" :alt="usuario.nombres" />|
        </v-avatar>
      </router-link>

      <v-btn text @click="logout">
        <span class="gris--text">Salir</span>
        <v-icon right>mdi-location-exit</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-card outlined raised>
        <v-layout column align-center>
          <v-avatar size="150" class="my-2">
            <img v-if="logo!=null" :src="logo" />
            <img v-else src="@/assets/images/food.jpg" />
          </v-avatar>
        </v-layout>
        <p class="skim--text" align="center">{{negocio}}</p>
      </v-card>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="link in public_links"
          :key="link.text"
          link
          :to="link.route"
          class="tile"
        >
          <v-list-item-icon>
            <v-icon color="gris" class="secondary--text">{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="skim--text subtitle-1">{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/ventas" class="tile">
          <v-list-item-icon>
            <v-icon medium color="gris" class="secondary--text">mdi-cash-fast</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="skim--text subtitle-1 pt-2">
              Ventas
              <v-chip class="float-right" small color="accent">{{ventas}}</v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>

      <v-list v-if="usuario.role==1">
        <v-list-group prepend-icon="mdi-cog">
          <template v-slot:activator>
            <v-list-item-title class="skim--text">Configuración</v-list-item-title>
          </template>
          <v-list-item v-for=" lin in admin_links" :key="lin.text" link :to="lin.route">
            <v-list-item-title class="skim--text subtitle-1">{{lin.text}}</v-list-item-title>
            <v-list-item-icon>
              <v-icon class="accent--text">{{lin.icon}}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="left" fixed temporary>xxx</v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    source: String
  },
  data() {
    return {
      drawer: null,
      miniVariant: true,
      drawerRight: null,
      right: false,
      left: false,
      ventas: 0,
      show: false,
      public_links: [
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          route: "/",
          role: 1
        },
        {
          icon: "mdi-point-of-sale",
          text: "Punto de Venta",
          route: "/venta",
          role: 0
        }
      ],

      admin_links: [
        {
          icon: "mdi-truck-cargo-container",
          text: "Proveedores",
          route: "/providers",
          role: 1
        },
        {
          icon: "mdi-clipboard-list",
          text: "Categorias",
          route: "/categorias",
          role: 1
        },

        {
          icon: "mdi-food",
          text: "Menu",
          route: "/menu",
          role: 1
        },
        {
          icon: "mdi-storefront",
          text: "Mi Negocio",
          route: "/negocio",
          role: 1
        },
        {
          icon: "mdi-chart-pie",
          text: "Recetas",
          route: "/recetas",
          role: 1
        },
        {
          icon: "mdi-inbox-multiple",
          text: "Inventario",
          route: "/inventario",
          role: 1
        },
        {
          icon: "mdi-scale",
          text: "Cotizador",
          route: "/cotizador",
          role: 1
        }

      ],

      usuario: {
        nombres: "",
        thumb: "",
        role: 0
      },
      negocio: "",
      logo: ""
    };
  },
  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login")
      })
    },
    initialize(id) {
      var today = new Date()
      var dd = today.getDate()
      var mm = today.getMonth() + 1
      var yyyy = today.getFullYear()
      if (dd < 10) {
        dd = "0" + dd
      }
      if (mm < 10) {
        mm = "0" + mm
      }
      this.date1 = yyyy + "-" + mm + "-" + dd;

      this.$store
        .dispatch("retrieve_ventas_hoy", {
          id: id,
          date1: this.date1
        })
        .then(response => {
          this.ventas = response
        })
        .catch(err => {
          console.log(err)
          if (err == 401) {
            this.$store.dispatch("refesh_token")
            this.initialize(id)
          }
        });
    }
  },
  created () {
    
    /* let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }

    this.initialize(user.id)

    this.usuario.nombres = user.name
    this.usuario.thumb = user.picture
    this.usuario.role = user.rl */

    /* this.negocio = user.negocio
    this.logo = user.logo */
    this.usuario.role = 1

    this.$root.$on("cambiarNombre", unNombre => {
      this.usuario.nombres = unNombre.nombres
      this.usuario.thumb = unNombre.picture
    })

    this.$root.$on("cambiarNegocio", unNegocio => {
      this.negocio = unNegocio.negocio
      if (unNegocio.logo != "") {
        this.logo = unNegocio.logo
      }
    })

    this.$root.$on("otraventa", otra => {
      this.ventas = Number(this.ventas + otra.suma)
    })
  }
}
</script>

<style scoped>
  .tile {
    margin: 9px;
    border-radius: 9px;
  }

  .tile {
    background: #e0e0e0;
  }

  .v-list-item--active {
    background-color: #83ebb6;
  }


  btn__content .v-icon {
    color: #0f3066;
  }

  .theme--dark.v-icon {
    color: #3d2c36;
  }
</style>
