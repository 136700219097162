<template>
    <v-container fluid class="pl-2 pb-4 bg">
        <v-sheet v-if="!platos"
          :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
          class="px-3 pt-3 pb-3"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="800"
            height="500"
            type="image, list-item-avatar"
          ></v-skeleton-loader>
        </v-sheet>

        <template v-else>
            <v-row class="pl-4 pb-2 pt-2">
                <v-card 
                    raised shaped 
                    class="mx-1 mb-1" 
                    dark min-width="100px" 
                    color="skim" 
                    @click="filtrarPlatos('')">
                    <v-card-title>
                        <v-icon small left color="accent">mdi-clipboard-list</v-icon>
                        <span class="title font-weight-light body-2 accent--text">Todos</span>
                    </v-card-title>
                </v-card>
                <v-card
                    v-for=" (categoria, index) in categories"
                    raised
                    shaped
                    class="mx-1 mb-1" 
                    :key="categoria.id" 
                    min-width="100px" 
                    :color=" categoria.color==null? 'skim--latte accent': categoria.color" 
                    @click="filtrarPlatos(categoria.nombrecat)">
                    <v-card-title>
                        <v-icon small left >{{categoria.icon}}</v-icon>
                        <span class="title font-weight-light body-2 skim--tet">{{ categoria.nombrecat }}</span>
                    </v-card-title>
                </v-card>
            </v-row>
            <v-row >
                <v-col cols="12" sm="8" md="8" class="px-4">
                    <v-layout row wrap>
                        <v-flex md3 v-for=" (plato, index) in platos" :key="plato.id" class="px-1 py-1">
                            <v-card dark color="white" outlined raised @click="addToSales(index,plato)" >
                                <v-layout column align-center>
                                    <v-avatar size='100' class="my-2">
                                        <img v-if="plato.foto1!=null" :src="plato.foto1">
                                        <img v-else src="@/assets/images/food.jpg">
                                    </v-avatar>
                                </v-layout>
                                <p class="gris--text" align="center">{{plato.nombreplato}}</p>
                                <v-card-actions>
                                    <v-btn block color="accent" class="skim--text" >
                                        <!-- <v-icon large left>add_box</v-icon> -->
                                        {{plato.precioventa}}&nbsp;Bs
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="px-1">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(venta, i) in ventas" :key="i" hide-actions>
                            <v-expansion-panel-header>
                                <v-row class="spacer" no-gutters>
                                    <v-col sm="3" md="4">
                                        <div class="font-weight-thin">Producto</div>
                                        {{venta.name}}
                                    </v-col>

                                    <v-col sm="5" md="3">
                                        <div class="font-weight-thin">Precio</div>
                                        <v-chip>
                                            <strong v-html="venta.precio"></strong>
                                        </v-chip>
                                    </v-col>

                                    <v-col sm="2" md="2">
                                        <div class="font-weight-thin">Unids</div>
                                        <strong v-html="venta.unidades"></strong>
                                    </v-col>
                                    <v-col sm="2" md="2">
                                        <div class="font-weight-thin">Subtotal</div>
                                    {{ venta.subtotal }}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <v-divider></v-divider>
                                <v-btn-toggle v-model="toggle_exclusive">
                                    <v-btn small icon color="wood" @click="aumentar(venta)">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <v-btn small icon color="wood" @click="restar(venta)">
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-card dark color="skim" width="100%">
                        <v-card-text>
                            <v-container>
                                <v-layout row wrap>
                                    <v-row>
                                        <v-col cols="6" sm="6">
                                            <h1>Total:</h1>
                                        </v-col>
                                        <v-col cols="6" sm="6" class="display-3">
                                            {{calcularTotal}} <span class="ml-1 subtitle-1">Bs</span>
                                        </v-col>
                                </v-row>
                                </v-layout>
                            </v-container>
                        </v-card-text>                        
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <Pagar @ventaAdded="ventaAgregada" :ventas="ventas" :total="total" :user="user" :disabled="disabled"/>
                                </v-col>
                                <v-col>
                                    <Confirmar @cleanVentas="clean" :disabled="disabled"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>        
        <v-snackbar v-model="snackbar" :timeout="6000" top color="accent" class="title">
            <span>Increible! Realizaste una Venta.</span>
            <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import Pagar from '@/components/ventas/Pagar.vue'
import Confirmar from '@/components/ventas/Confirmar.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
    inject: ['theme'],
    components: {
        Pagar, Confirmar
    },

    data() {
        return {
            toggle_exclusive: 2,
            toggle_exclusive2: 3,
            text: 'center',
            toggle_multiple: [0, 1, 2],
            plats: null,
            ventas: [],
            cats: [],
            snackbar: false,
            user: null,
            total: 0,
            disabled: true,
            ventas: []
        }
    },

    methods: {
        ...mapActions(['retrievePlatosNegocio', 'filtrarPlatos']),

        addToSales(index,item){

            let found = this.ventas.find(obj => obj.id == item.id)

            if( found === undefined){
                this.ventas.push({
                    id: item.id,
                    index: index,
                    name: item.nombreplato,
                    unidades: 1,
                    precio: item.precioventa,
                    prepa: item.precioprepa,
                    subtotal: item.precioventa
                })
            }else {
                found.unidades = Number(found.unidades + 1)
                found.subtotal = found.precio*Number(found.unidades)
                //this.ventas[found.index] = found
            }
        },

        aumentar(itemA){
            let foundA = this.ventas.find(
                elementA => elementA.id == itemA.id
            );
            if(foundA!=undefined){
                foundA.unidades = Number(foundA.unidades + 1)
                foundA.subtotal = foundA.precio*Number(foundA.unidades)
                //this.ventas[foundA.index] = foundA
            }
        },

        restar(itemR){          
            let foundR = this.ventas.find(
                elementR => elementR.id == itemR.id
            );
            if(foundR!=undefined){
                if(foundR.unidades==1){
                    if(this.ventas.length>1){
                        //this.ventas.splice(foundR.index, 1)
                        this.ventas = this.ventas.filter(item => item.index !== foundR.index)
                    } else{
                        this.ventas = []
                    }
                } else {
                    foundR.unidades = Number(foundR.unidades - 1)
                    foundR.subtotal = foundR.precio*Number(foundR.unidades)
                    //this.ventas[foundR.index] = foundR
                }
            }
        },

        clean(){
            this.ventas = []
        },        

        elegidos(pla, id){
            pla.categorias.forEach( function(cate, indice, array) {
                return cate.id == id
            })
        },

        ventaAgregada(){
            this.ventas = []
            this.snackbar = true
        }

    },

    computed: {
        ...mapGetters(['platos', 'categories']),
        calcularTotal(){
            /* this.total = 0
            let suma = 0
            this.ventas.forEach( function(valor, indice, array) {
                suma = Number(suma) + Number(valor.subtotal)
            })
            this.total = suma */

            this.total = this.ventas.reduce( (sum, elem)=> sum + elem.subtotal, 0)

            return this.total
        }
    },

    created() {
        this.retrievePlatosNegocio()
    },

    watch: {
        total (value, oldVal){
            if(value>0){
                this.disabled = false
            }else{
                this.disabled = true
            }
        },
    },
}

</script>

<style>


</style>