<template>
  <div class="gradient">
    <v-container class="lighten-5">
      <h1 class="subheading latte--text mt-3">VisualPos - Bienvenido</h1>
      <v-row class="mb-6" no-gutters>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mr-3" outlined tile>
            <DonaDemo ref="dona" :data="chartData" :options="chartOptions" />
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="mt-4">
          <v-card class="mr-3" outlined tile>
            <BarDemo v-if="loaded"  :chartdata="chartdata" />
          </v-card>
        </v-col>
        <v-col cols="12" sm="14" md="4" class="mt-6">
          <v-card class="mt-4 mx-auto" height="95%">
            <v-sheet
              class="v-sheet--offset mx-auto"
              color="cyan"
              elevation="12"
              max-width="calc(100% - 32px)"
            >
              <v-sparkline
                :labels="labels"
                :value="value"
                color="white"
                line-width="2"
                padding="16"
              ></v-sparkline>
            </v-sheet>

            <v-card-text class="pt-0">
              <div class="title font-weight-light mb-2">User Registrations</div>
              <div class="subheading font-weight-light grey--text">Last Campaign Performance</div>
              <v-divider class="my-2"></v-divider>
              <v-icon class="mr-2" small>mdi-clock</v-icon>
              <span class="caption grey--text font-weight-light">last registration 26 minutes ago</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-card class="mx-auto">
            <v-toolbar color="accent accent-4" dark>
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
              <v-toolbar-title>Top Ventas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-list subheader>
              <v-subheader>Platos</v-subheader>
              <v-list-item v-for="(detail, index) in topventas" :key="index">
                <v-list-item-content>
                  <v-list-item-title v-text="detail.plato"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-chip small>{{detail.cuantos}}</v-chip>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>
            <!-- <pre>{{topventas}}</pre> -->
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card class="mx-auto">
            <v-toolbar color="accent accent-4" dark>
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
              <v-toolbar-title>Top Ventas</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-list subheader>
              <v-subheader>
                Total Platos:
                <strong>{{calcularTotal}}</strong>
              </v-subheader>
              <div v-for="detalle in topventas" :key="detalle.cuantos" class="my-2 mx-1">
                <v-progress-linear
                  :color="detalle.color"
                  :value="porcentaje(detalle.cuantos)"
                  height="25"
                  reactive
                >
                  <span>{{detalle.plato}}</span>
                  <strong class="ml-2">{{porcentaje(detalle.cuantos)}}</strong>%
                </v-progress-linear>
                <v-divider></v-divider>
              </div>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DonaDemo from "@/components/charts/DonaDemo.vue"
import BarDemo from "@/components/charts/BarDemo.vue"

export default {
  components: {
    DonaDemo,
    BarDemo
  },
  data() {
    return {
      usuario: null,
      year: 2020,
      ventasAnio: null,

      colores: [],
      labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value: [200, 675, 410, 390, 310, 460, 250, 240],
      topventas: [],
      total: 0,

      chartOptions: {
        hoverBorderWidth: 20
      },
      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [],
            data: []
          }
        ]
      },

      meses: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Juno', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],

      loaded: false,

      chartdata: null

    }
  },

  created() {    
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }
    this.usuario = user
    this.year = new Date().getFullYear()
    this.initialize()
    this.fetchVentasMes()
  },
  methods: {
    initialize() {
      this.$store
        .dispatch("retrieve_ranking")
        .then(response => {
          this.topventas = response
          //console.log(this.topventas)
          let etiquet = []
          let dats = []
          let cols = []
          this.topventas.forEach(function(valor, indice, array) {
            etiquet.push(valor.plato)
            dats.push(valor.cuantos)
            let color = "#" + Math.floor(Math.random() * 16777215).toString(16)
            cols.push(color)
            valor.color = color
          })

          this.colores = cols

          this.chartData = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            labels: etiquet,
            datasets: [
              {
                label: "Data One",
                backgroundColor: cols,
                data: dats
              }
            ]
          }

          this.$refs.dona.renderChart(this.chartData);
        })
        .catch(err => {
          console.log(err)
          if (err == 401) {
            this.$store.dispatch("refesh_token")
            this.initialize()
          }
        });
    },

    porcentaje(valor) {
      if (this.total != 0) {
        let porcent = Number((valor * 100) / this.total)
        return porcent.toFixed(0)
      }
    },

    fetchVentasMes() {
      this.loaded = false
      this.$store
        .dispatch("retrieve_ventas_anio", { year: this.year })
        .then(response => {
          this.ventasAnio = response

          this.meses = this.ventasAnio.map(function(venta) {
            return venta.mes
          })
          
          var datos = this.ventasAnio.map(function(venta){
              return venta.ventas
          })

          this.chartdata = {
            labels: this.meses,
            datasets: [
              {
                label: 'Ventas 2020',
                backgroundColor: "#f87979",
                data: datos
              }
            ]
          }
          this.loaded = true

        })
        .catch(err => {
          console.log(err)
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchVentasMes()
          }
        });
    }
  },

  computed: {
    calcularTotal() {
      this.total = 0
      let suma = 0

      this.topventas.forEach(function(valor, indice, array) {
        suma = Number(suma) + Number(valor.cuantos)
      });

      this.total = suma
      return this.total
    }
  }
}
function myFunction(valor) {
  return valor.cuantos
}
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
