<template>
    <v-dialog v-model="dialog" persistent max-width="450" eager>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="errore" :disabled="disabled" 
            block class="subtitle-2 mx-n1">Cancelar</v-btn>
      </template>
      <v-card dark color="latte">
        <v-card-title class="headline">            
        </v-card-title>
        <v-card-text>
            <v-alert dark type="warning" dense icon="warning" class="skim--text">
                {{ mensaje }}
            </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if="valido">
            <v-btn rounded color="green darken-1" @click="close">No</v-btn>
            <v-btn rounded color="error darken-1" @click="deleteItem" :loading="loading">Si</v-btn>
          </template>
          <template v-else>
            <v-btn color="green darken-1" text @click="dialog = false">Cerrar</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: 'Confirmar',
    props:{
        disabled: Boolean
    },
    data () {
      return {
        loading: false,
        dialog: false,
        mensaje: '¿Esta seguro que desea eliminar?',
        valido: true
      }
    },
    methods:{
        deleteItem(){
          this.$emit("cleanVentas")
          this.dialog = false
        },
        close(){
          this.dialog = false
        }
    },
    created(){
    }
  }
</script>