<template>
  <v-dialog max-width="700px" persistent v-model="dialog" eager>
    <template v-slot:activator="{ on }">
      <v-btn fab class="verde" v-on="on">
        <v-icon large>mdi-plus-thick</v-icon>
      </v-btn>
    </template>
    <v-card color="skim" dark>
      <v-card-title class="success">
        Nueva Salida
      </v-card-title>

      <v-form class="px-6 py-3" ref="form" v-model="valid" :lazy-validation="lazy">
        <v-row no-gutters>
          <v-col cols="10" sm="10" md="10">
            <v-text-field
              v-model="buscar"
              label="Buscar"
              dense
              autocomplete="off"
              filled
              rounded
              :disabled="buscarActive"
            ></v-text-field>
            <!-- <span>{{buscar}}</span> -->
          </v-col>
          <v-col cols="2">
            <v-btn large icon class="my-2" @click="buscarProducto">
              <v-icon large color="verde">mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-select
              v-model="productoElegido"
              chips
              clearable
              return-object
              :items="copiaProductos"
              item-text="name"
              item-value="name"
              v-on:change="changeRoute"
              filled
              rounded
              label="Productos"
              single-line
              :rules="[(v) => !!v || 'Producto es requerido']"
              required
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="6" class="pb-3">
            <v-card
              dark
              height="55px"
              v-if="productoElegido != null"
              color="verde"
              class="px-3 py-1 skim--text"
            >
              <v-row no-gutters>
                <v-col>
                  <div class="font-weight-thin">Saldo</div>
                  <v-chip small color="skim">
                    {{
                    productoElegido.saldo
                    }}
                  </v-chip>
                </v-col>
                <v-col>
                  <div class="font-weight-thin">Medida</div>
                  <v-chip small color="gris">
                    {{
                    productoElegido.unids
                    }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="cantidad"
              label="Cantidad"
              dense
              autocomplete="off"
              filled
              rounded
              type="number"
              :min="0"
              :error-messages="errorCantidad"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-card-actions>
            <v-btn
              rounded
              :disabled="!valid"
              @click="submit"
              color="green white--text"
              :loading="loading"
            >
              Proceder
            </v-btn>
            <v-btn text rounded @click="close">Cerrar</v-btn>
          </v-card-actions>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NuevaSalida",
  props: {
    disabled: Boolean,
    idu: Number,
    productos: Array
  },
  data() {
    return {
      valid: false,
      lazy: false,
      loading: false,
      dialog: false,
      state: "activo",
      buscarActive: false,

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      costo: "",
      cantidad: "",
      buscar: "",
      productoElegido: null,
      copiaProductos: [],
      iduser: 0,
      errorCantidad: ""
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let fData = {
          cantidad_salida: this.cantidad,
          users_id: this.iduser,
          productos_id: this.productoElegido.id
        };

        //console.log(fData)
        this.$store
          .dispatch("create_salida", fData)
          .then(response => {
            //console.log('producto creado, id:'+response.data.data)
            fData.name = this.productoElegido.name;
            fData.id = response.data.data.id;
            fData.fecha_salida = response.data.data.fecha;
            fData.picture = this.productoElegido.picture;
            this.loading = false;
            this.$emit("salidaAdded", fData);
            this.close();
          })
          .catch(errore => {
            this.valid = true;
            this.loading = false;
          });
      }
    },

    changeRoute(a) {},

    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },

    buscarProducto() {
      //console.log()
      var cad = this.buscar;
      if (this.buscar != "") {
        var results = this.copiaProductos.filter(function(producto) {
          return producto.name == cad;
        });
        this.copiaProductos = results;
      } else {
        this.copiaProductos = this.productos;
      }
    }
  },

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }
    this.iduser = user.idu;

    if (this.dialog) {
      this.$refs.form.resetValidation();
    }
  },

  watch: {
    dialog(value, oldvalue) {
      if (value) {
        this.copiaProductos = this.productos;
      }
    },

    cantidad(value, oldvalue) {
      //console.log(value)
      if (this.productoElegido != null && value > this.productoElegido.saldo) {
        this.valid = false;
        this.errorCantidad = "Saldo insuficiente";
      } else {
        this.valid = true;
        this.errorCantidad = "";
      }
    }
  }
};
</script>

<style>
.v-text-field input {
  font-size: 1.1em;
}
</style
>>
