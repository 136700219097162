<template>
  <!-- max-width="900px"  -->
  <v-dialog persistent v-model="dialog" max-width="980px">
    <template v-slot:activator="{ on }">
      <v-btn small icon class="py-8" v-on="on">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>

    <v-card dark color="skim">
      <v-card-title class="py-1">
        <h3 class="gray--text">Editar Producto</h3>
      </v-card-title>
      <v-layout justify-center wrap>
        <v-flex xs12 md6 sm12>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="name"
                    label="Nombre del Producto"
                    filled
                    rounded
                    dense
                    :rules="requiredRule"
                    :error-messages="errorProductoName"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 md6>
                  <v-text-field
                    v-model="entrada"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Entradas"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 md6>
                  <v-text-field
                    v-model="salida"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Salidas"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md12>
                  <v-text-field
                    v-model="saldo"
                    label="Saldo"
                    filled
                    rounded
                    dense
                    :min="0"
                    :rules="requiredRule"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm9 md9>
                  <v-select
                    class="mt-0"
                    filled
                    rounded
                    clearable
                    v-model="unids"
                    :error-messages="errorUnidades"
                    :items="unidades"
                    item-text="uname"
                    item-value="uname"
                    chips
                    return-object
                    label="Unidad"
                    single-line
                    :rules="[v => !!v || 'Unidad es requerida']"
                    required
                    v-on:change="changeRoute2"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm9 md9>
                  <v-select
                    class="mt-0"
                    filled
                    rounded
                    clearable
                    v-model="grupo"
                    :error-messages="errorCats"
                    :items="grupos"
                    item-text="gname"
                    item-value="id"
                    chips
                    return-object
                    label="Categoria"
                    single-line
                    :rules="[v => !!v || 'Categoria es requerida']"
                    required
                    v-on:change="changeRoute"
                  ></v-select>
                </v-flex>

                <v-flex xs6 sm3 md3>
                  <v-btn rounded large :color="colorNewCat" @click="addNuevaCat">
                    <v-icon large>{{nuevacatIcon}}</v-icon>
                  </v-btn>
                </v-flex>

                <template v-if="verNuevacat">
                  <v-flex xs12 md9>
                    <v-text-field
                      v-model="nuevacat"
                      filled
                      rounded
                      dense
                      label="Nueva Categoria"
                      :rules="requiredRule"
                      :error-messages="errorNuevacat"
                      autocomplete="off"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm3 md3>
                    <v-btn rounded large class="verde" @click="createCat" :loading="loading2">
                      <v-icon large>done</v-icon>
                    </v-btn>
                  </v-flex>
                </template>
              </v-layout>
            </v-container>
          </v-form>
          <!-- <pre>{{grupos}}</pre> -->
        </v-flex>
        <v-flex xs12 md6 sm12 class="py-1">
          <v-card class="pa-0 pr-2" height="440" color="coffeemilk">
            <v-img v-if="picture!=null" :src="picture" :alt="name" contain></v-img>
          </v-card>
          <CropperImage @imagenChanged="imagenCambiada" />
        </v-flex>
        <v-flex xs12>
          <v-card-actions>
            <v-btn
              :disabled="!valid"
              @click="submit"
              class="green white--text"
              :loading="loading"
              rounded
            >
              Guardar
              <v-icon right>send</v-icon>
            </v-btn>
            <v-btn class="white--text" @click="close" text rounded>
              Cancelar              
            </v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
      <!-- </v-container> -->
    </v-card>
    <v-snackbar v-model="verMensaje" color="success" :timeout="3000" top>{{ imageResultMesage }}</v-snackbar>
  </v-dialog>
</template>

<script>
//import { VueCropper } from "vue-cropper";
import CropperImage from "@/components/platos/CropperImage.vue";

export default {
  name: "NuevoProducto",
  props: {
    grupos: Array,
    producto: Object
  },
  components: {
    CropperImage
  },
  data() {
    return {
      verMensaje: false,
      imageResultMesage: "",
      valid: true,
      lazy: false,
      loading: false,
      dialog: false,

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      errorProductoName: "",

      hasImage: false,
      image: null,
      vistaPrevia: true,
      type: "",
      verFotos: false,
      errorCats: "",
      errorUnidades: "",
      unidades: [
        { uname: "Gramos" },
        { uname: "Kilogramo" },
        { uname: "Cuartilla" },
        { uname: "Arroba" },
        { uname: "Litro" },
        { uname: "Unidades" }
      ],

      verNuevacat: false,
      nuevacat: "",
      idNuevacat: 0,
      errorNuevacat: "",
      nuevacatIcon: "add",
      colorNewCat: "accent",
      loading2: false,

      name: "",
      marca: "",
      picture: null,
      grupo: null,
      entrada: 0,
      salida: 0,
      saldo: 0,
      unids: "",
      idu: 0,
      id: 0
    };
  },

  methods: {
    createCat() {
      if (this.nuevacat != "") {
        this.loading2 = true;
        var categoryData = {
          gname: this.nuevacat,
          users_id: this.idu,
          gicon: "",
          gcolor: ""
        };

        this.$store
          .dispatch("create_grupo", categoryData)
          .then(response => {
            this.loading2 = false;
            this.nuevacat = "";
            categoryData.id = response.data.data;
            this.grupos.push(categoryData);
            this.grupo = categoryData;
            //this.categorias.push(categoryData)
            this.nuevacatIcon = "add";
            this.colorNewCat = "accent";
            this.verNuevacat = false;
            this.valid = true;
            this.errorCats = "";
          })
          .catch(err => {
            if (err.response.status == 422) {
              console.log(err);
            }
          });
      }
    },

    addNuevaCat() {
      this.verNuevacat = !this.verNuevacat;
      if (this.verNuevacat) {
        this.nuevacatIcon = "clear";
        this.colorNewCat = "coffeemilk";
      } else {
        this.nuevacatIcon = "add";
        this.colorNewCat = "accent";
      }
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$refs.form.validate()) {
          let fData = {
            name: this.name,
            entrada: this.entrada,
            salida: this.salida,
            saldo: this.saldo,
            picture: this.picture,
            grupos_id: this.grupo.id,
            unids: this.unids,
            id: this.producto.id
          };

          //console.log(fData)
          this.$store
            .dispatch("update_producto", fData)
            .then(response => {
                this.producto.name = this.name
                this.producto.entrada =  this.entrada
                this.producto.picture = this.picture
                this.producto.salida = this.salida
                this.producto.grupos_id = this.grupos_id
                this.producto.unids = this.unids
                this.producto.saldo = this.saldo
              this.close();
            })
            .catch(errore => {
              this.valid = true;
              this.loading = false;
            });
        }
      }
    },

    close() {
      this.loading = false;
      this.dialog = false;
    },

    changeRoute(a) {},

    changeRoute2() {},

    imgLoad(msg) {
      //console.log(msg)
    },

    checkProductoName(value) {
      let fdata = {
        name: value,
        idu: this.idu
      };

      this.$store
        .dispatch("check_pname", fdata)
        .then(response => {
          if (response.status == 200) {
            this.errorProductoName = "";
            this.valid = true;
          }
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorProductoName = "Nobre de Producto ya existe";
            this.valid = true;
          }
        });
    },

    chekCateName(valor) {
      this.$store
        .dispatch("check_gname", {
          gname: valor,
          idu: this.idu
        })
        .then(response => {
          this.errorNuevacat = "";
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorNuevacat = "Nombre Categoria ya existe";
            this.valid = false;
          }
        });
    },

    imagenCambiada(imagen) {
      this.picture = imagen;
    }
  },

  /*      watch: {
      name (value, oldVal) {
          if(value){
              this.checkProductoName(value)
          }
      },

      nuevacat (value, oldvalue){
          if(value!=''){
              this.chekCateName(value)
          }
      }

    }, */

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }

    this.name = this.producto.name;
    this.marca = "";
    this.entrada = this.producto.entrada;
    this.salida = this.producto.salida;
    this.saldo = this.producto.saldo;
    this.unids = this.producto.unids;
    this.grupo = {
      id: this.producto.grupos_id,
      gname: this.producto.gname
    };

    this.picture = this.producto.picture;

    //this.option.img = this.producto.picture
    this.hasImage = false;
    this.image = null;
    this.idu = user.idu;
    this.loading = false;
  }
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.cardbg {
  background: url("../../assets/images/h1.jpg") no-repeat center center;
  background-size: cover;
}
</style>