<template>
    <v-main class="bg">
        <v-container fluid fill-height >
            <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4 lg4>
                    <v-card class="latte elevation-6 pa-3" dark raised >
                        <v-card-text>
                            <div class="layout column align-center">
                                <img src="@/assets/images/cage.png" alt="myFood app" height="200px">
                                <h1 class="flex my-4 success--text">myFood</h1>
                            </div>
                            <v-form>
                                <v-text-field append-icon="mdi-account" name="login" label="Login" type="text" 
                                v-model="userEmail" filled rounded dense
                                :error="error" :rules="[rules.required]" autocomplete="off"/>

                                <v-text-field :type="hidePassword ? 'password' : 'text'" filled rounded dense
                                :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'" 
                                name="password" label="Password" id="password" :rules="[rules.required]" v-model="password" 
                                :error="error" @click:append="hidePassword = !hidePassword" />
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn block rounded color="accent" @click="login" :loading="loading">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-snackbar v-model="showResult" :timeout="2000" top>
            {{ result }}
        </v-snackbar>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            userEmail: '',
            password: '',
            hidePassword: true,
            error: false,
            showResult: false,
            result: '',
            rules: {
                required: value => !!value || 'Required.'
            },            
        }
    },

    methods: {
        login() {
            const vm = this
            this.loading = true
            if (!vm.userEmail || !vm.password) {
                vm.result = "Email and Password can't be null."
                vm.showResult = true
                return
            }
            let email = vm.userEmail
            let password = vm.password
            this.$store
                .dispatch("login", {
                    email,
                    password
                })
                .then(() => {
                    this.loading = false
                    this.$router.push("/")
                })
                .catch(
                    err => {
                        this.loading = false;
                        vm.error = true;
                        vm.result = "Email or Password is incorrect."
                        vm.showResult = true
                    }
                )
        }
    }
}
</script>

<style lang="css" scoped>
#login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
}
</style>
