<template>
  <v-dialog max-width="700px" persistent v-model="dialog" eager>
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="on">
        <v-icon>mdi-database-plus</v-icon>
      </v-btn>
    </template>

    <!--     <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small icon v-bind="attrs" v-on="on">
          <v-icon>mdi-database-plus</v-icon>
        </v-btn>
      </template>
      <span>Tomar del Invetario</span>
    </v-tooltip>-->

    <v-card>
      <v-card-title>{{ingrediente.producto}}</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="6">
            <v-card height="200">
              <v-card-title>Estimado</v-card-title>
              <v-card-text>
                <h3>Cantidad:</h3>
                <v-chip large>{{ingrediente.necesario.cant2}}</v-chip>
                <v-chip color="skim" small dark>{{ingrediente.necesario.medida2}}</v-chip>
              </v-card-text>
              <v-card-actions>
                <v-sheet v-if="validError" class="error" rounded height="34px" width="350px">
                  <h3 class="white--text px-2 py-2 text-center">{{mensajeError}}</h3>
                </v-sheet>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-card dark color="skim" height="200" v-if="producto!=null">
              <v-card-title>Inventario</v-card-title>
              <v-card-text>
                <h2 class="white--text">
                  Producto:
                  <span class="verde--text">{{producto.name}}</span>
                </h2>
                <h3 class="white--text subtitle pb-2">
                  Saldo:
                  <span class="verde--text pr-2 pb-1">{{producto.saldo}}</span>
                  <v-chip color="verde skim--text" small dark>{{producto.unids}}</v-chip>
                </h3>
                <v-btn
                  v-if="!validError"
                  small
                  rounded
                  class="verde skim--text"
                  @click="confirmar=true"
                >Tomar del Inventario</v-btn>
              </v-card-text>
              <v-card-actions>
                <template v-if="confirmar">
                  <v-btn x-small rounded class="white skim--text" @click="continuar">Confirmar</v-btn>
                  <v-btn x-small text rounded class="white--text" @click="cancelar">Cancelar</v-btn>
                </template>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn rounded class="green white--text" :disabled="!valid" 
          :loading="loading"
        @click="proceder">Proceder</v-btn>
        <v-btn text rounded @click="dialog=false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Salida",
  props: {
    ingrediente: Object
  },
  data() {
    return {
      dialog: false,
      producto: null,
      usuario: null,
      valid: false,
      confirmar: false,
      mensajeError: "",
      validError: false,
      loading: false
    };
  },

  methods: {
    fetchProducto() {
      this.$store
        .dispatch("retrieve_producto", {
          idu: this.usuario.idu,
          name: this.ingrediente.producto
        })
        .then(response => {
          this.producto = response;
          if (this.producto.saldo < this.ingrediente.necesario.cant2) {
            this.mensajeError = "Saldo de Inventario Insuficiente";
            this.validError = true;
          }
        })
        .catch(err => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchProducto();
          }
        });
    },
    continuar() {
      this.valid = true;
      this.confirmar = false;
    },
    cancelar() {
      this.valid = false;
      this.confirmar = false;
    },
    proceder() {
      this.loading = true
      let fData = {
        cantidad_salida: this.ingrediente.necesario.cant2,
        users_id: this.usuario.idu,
        productos_id: this.producto.id
      };
      this.$store
        .dispatch("create_salida", fData)
        .then(response => {
          this.ingrediente.state = 'taked'
          this.ingrediente.index = this.index
          this.$emit("cambiarEstado", this.ingrediente);
          this.loading = false;
          this.dialog = false;
        })
        .catch(errore => {
          this.valid = true;
          this.loading = false;
        });

        this.$emit("cambiarEstado", this.ingrediente.id);
        this.loading = false;
        this.dialog = false;
    }
  },

  created() {
    let usuario = this.$store.getters.authUser;
    if (typeof usuario.name === "undefined") {
      usuario = JSON.parse(usuario);
    }
    this.usuario = usuario;
  },

  watch: {
    dialog(value, oldVal) {
      if (value) {
        this.fetchProducto();
      }
    }
  }
};
</script>

<style scoped>
</style>