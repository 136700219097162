<template>
  <v-dialog persistent v-model="dialog" width="1100px">
    <template v-slot:activator="{ on }">
      <v-btn icon small v-on="on">
        <v-icon color="skim">assignment</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="3" class="gris text-center white--text">Gramaje por receta</th>
                    <th colspan="2" class="wood text-center">Costo mercado</th>
                    <th colspan="1" class="gris text-center white--text">Costo gramaje</th>
                  </tr>
                  <tr>
                    <th class="cancel text-left">Producto</th>
                    <th class="cancel text-left">CANT.</th>
                    <th class="cancel text-left text-center">UNID.</th>
                    <th class="coffeemilk text-left">Bs.</th>
                    <th class="coffeemilk text-left text-center">UNID.</th>
                    <th class="verde text-left text-center">TOTALES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in ingredientes" :key="item.name">
                    <td class="text-left">{{ item.producto }}</td>
                    <td class="text-right">{{ item.cantidad }}</td>
                    <td class="text-center">{{ item.unidad1 }}</td>
                    <td class="text-right">{{ item.costomercado }}</td>
                    <td class="text-center">{{ item.unidad2 }}</td>
                    <td class="text-right">{{ item.total }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn rounded class="error white--text" @click="dialog=false">Cerrar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DetalleReceta",
  props: {
    receta: Object
  },
  data() {
    return {
      dialog: false,
      lazy: false,
      loading: false,
      usuario: null,
      ingredientes: []
    };
  },

  methods: {
    close() {
      this.dialog = false;
    },

    fetchIngredientes() {
      this.$store
        .dispatch("retrieve_ingredientes", {
          id: this.receta.id
        })
        .then(response => {
          this.ingredientes = response;
          this.ingredientes.sort((a, b) =>
            a["producto"] < b["producto"] ? -1 : 1
          );
        })
        .catch(err => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchIngredientes();
          }
        });
    }
  },

  created() {
    let usuario = this.$store.getters.authUser;
    if (typeof usuario.name === "undefined") {
      usuario = JSON.parse(usuario);
    }
    this.usuario = usuario;
  },

  watch: {
    dialog(value, oldVal) {
      if (value) {
        this.fetchIngredientes();
      }
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}
</style>
