<template>
  <v-dialog max-width="700px" persistent v-model="dialog" eager>
    <template v-slot:activator="{ on }">
      <v-btn small icon class="mr-4" v-on="on">
        <v-icon color="darkverde">mdi-information</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h4>Detalle de Venta</h4>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div>Nit/Ci:</div>
              <v-card class="pa-1" outlined>{{venta.nitci}}</v-card>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <div>Cliente:</div>
              <v-card class="pa-1" outlined>{{venta.cliente}}</v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <div>Tipo de Pago:</div>
              <v-card class="pa-1" outlined>{{venta.tipopago}}</v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <div>Total:</div>
              <v-card
                dark
                class="text-center pa-1"
                color="accent"
                outlined
              >{{venta.total}}&nbsp;bs.-</v-card>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <div>Efectivo:</div>
              <v-card class="pa-1" outlined>{{venta.efectivo}}</v-card>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <div>Cambio:</div>
              <v-card class="pa-1" outlined>{{venta.cambio}}</v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="6" sm="12" md="12">
              <v-simple-table fixed-header class="blanco">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="latte text-left blanco--text">Compra</th>
                      <th class="latte text-right blanco--text">Unid</th>
                      <th class="latte text-right blanco--text">Precio</th>
                      <th class="latte text-right blanco--text">Sub-Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="detalle in detalles" :key="detalle.idd">
                      <td class="text-left skim--text">{{detalle.plato}}</td>
                      <td class="text-right skim--text">{{detalle.unidades}}</td>
                      <td class="text-right skim--text">{{detalle.precio}}</td>
                      <td class="text-right skim--text">{{detalle.subtotal}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn class="error" color="white" text @click="close" rounded>Cerrar</v-btn>
            <v-btn @click="pdf" color="green" dark rounded>PDF</v-btn>
          </v-card-actions>
          <!-- <pre>{{item}}</pre> -->
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Detalle",
  props: {
    venta: Object,
    idu: Number
  },
  data() {
    return {
      lazy: false,
      dialog: false,
      detalles: []
    };
  },

  methods: {
    close() {
      this.dialog = false;
    },

    pdf() {},

    fetchDetalles(id) {
      this.$store
        .dispatch("retrieve_detalles", { id: id })
        .then(response => {
          this.detalles = response;
        })
        .catch(err => {
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchDetalles(this.venta.id);
          }
        });
    }
  },

  created() {},

  watch: {
    dialog(value, oldVal) {
      if (value) {
        this.fetchDetalles(this.venta.id);
      }
    }
  }
};
</script>

<style>
.v-text-field input {
  font-size: 1.1em;
}
</style>>
