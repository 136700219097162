import { render, staticRenderFns } from "./NuevoProducto.vue?vue&type=template&id=5ccc3d60&scoped=true&"
import script from "./NuevoProducto.vue?vue&type=script&lang=js&"
export * from "./NuevoProducto.vue?vue&type=script&lang=js&"
import style0 from "./NuevoProducto.vue?vue&type=style&index=0&id=5ccc3d60&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ccc3d60",
  null
  
)

export default component.exports