<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12>
        <v-card>
          <v-toolbar color="accent" dark src="@/assets/images/h1.jpg" height="100px">
            
            <v-toolbar-title>Proveedores</v-toolbar-title>
            <v-spacer></v-spacer>
            <NewProvider :idu="iduser" @addProvider="providerAgregado" />
          </v-toolbar>
          <v-divider></v-divider>

          <v-sheet
            v-if="!providers"
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="px-3 pt-3 pb-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="800"
              type="table-heading, table-thead, table-tbody, table-tfoot"
            ></v-skeleton-loader>
          </v-sheet>

          <div v-else class="scroll">

            <div v-for="(provider, index) in providers" :key="provider.id">
                <v-row no-gutters class="pb-2 pt-2">
                    <v-col class="text-center">
                        <div class="caption">##</div>
                        <div>{{index+1}}</div>
                    </v-col>
                    <v-col>
                        <div class="caption">Nombre</div>
                        <div>{{provider.razon}}</div>
                    </v-col>
                    <v-col>
                        <div class="caption">T.Fijo</div>
                        <div>{{provider.phones}}</div>
                    </v-col>
                    <v-col>
                        <div class="caption">Celular</div>
                        <div>{{provider.celular}}</div>
                    </v-col>
                    <v-col>
                      <div class="py-2">
                        <EditProvider :provider="provider" />
                      </div>
                    </v-col>
                </v-row>  
              <v-divider></v-divider>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" color="success" :timeout="3000" top>{{
      message
    }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import NewProvider from "@/components/providers/NewProvider"
import EditProvider from "@/components/providers/EditProvider"

export default {
  inject: ["theme"],
  components: {
    NewProvider,
    EditProvider
  },
  data() {
    return {
      snackbar: false,
      iduser: 0,
      message: "",
    }
  },

  methods: {
    ...mapActions(["retrieveProviders"]),  
    sortBy(param) {
      this.providers.sort((a, b) => (a[param] < b[param] ? -1 : 1))
    },

    providerAgregado(){
        this.message = "Proveedor agregado con exito!"
        this.snackbar = true
    }

  },

  computed: {
      ...mapGetters(["providers"])
  },

  created() {
    var user = this.$store.getters.authUser
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }
    this.iduser = user.idu

    this.retrieveProviders({idu:user.idu})
    .then(resp=>{
        //this.providers = resp
    })


  }
}
</script>