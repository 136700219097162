<template>
  <v-dialog persistent v-model="dialog" width="460px">
    <template v-slot:activator="{ on }">
      <v-btn rounded block v-on="on" class="verde skim--text">
        <v-icon>fas fa-pencil-alt</v-icon>Agregar
      </v-btn>
    </template>
    <v-card>
      <v-container class="py-0 px-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-form ref="formIn" v-model="valid2" :lazy-validation="lazy2">
              <v-card>
                <v-card-title>
                  <p class="subtitle-2 skim--text">Nuevo Gramaje por Receta</p>
                </v-card-title>
                <div class="px-1">
                  <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        :items="productos"
                        :filter="customFilter"
                        item-text="name"
                        label="Producto"
                        v-model="ingrediente.producto"
                        dense
                        outlined
                        rounded
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="5" md="6">
                      <v-text-field
                        v-model="ingrediente.cantidad"
                        label="Cantidad"
                        type="number"
                        :min="0"
                        :rules="requiredRule"
                        dense
                        flat
                        outlined
                        rounded
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="6">
                      <v-select
                        v-model="ingrediente.unidad1"
                        chips
                        clearable
                        class="py-n1"
                        dense
                        flat
                        outlined
                        rounded
                        :items="medidas1"
                        item-text="medida"
                        item-value="medida"
                        v-on:change="changeMedida1"
                        label="Medida"
                        single-line
                        :rules="[v => !!v || 'Requerido']"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="5" md="6">
                      <v-text-field
                        v-model="ingrediente.costomercado"
                        label="Costo Mercado"
                        type="number"
                        :min="0"
                        :rules="requiredRule"
                        dense
                        flat
                        outlined
                        rounded
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="7" md="6">
                      <v-select
                        v-model="ingrediente.unidad2"
                        chips
                        clearable
                        class="py-n1"
                        dense
                        flat
                        outlined
                        rounded
                        :items="medidas2"
                        item-text="medida"
                        item-value="medida"
                        v-on:change="changeMedida2"
                        label="Medida"
                        single-line
                        :rules="[v => !!v || 'Requerido']"
                        required
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-card dark class="px-2 py-1 title" color="gris" outlined>
                        <span class="subtitle-2">(Bs):</span>
                        <v-chip class="ml-3">{{calcularTotalGramaje}}</v-chip>
                      </v-card>
                    </v-col>
                    <!--                     <v-col cols="12">
                        {{ingrediente}}
                    </v-col>-->
                  </v-row>
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn rounded small class="verde mr-1 white--text" @click="addIngre" :disabled="!valid2">
                    Agregar
                  </v-btn>
                  <v-btn color="error" small gray text class="skim--text" @click="borrar">Cancelar</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NuevoGramaje",
  props: {
    productos: Array
  },
  data() {
    return {
      dialog: false,
      valid: false,
      lazy: false,
      valid2: false,
      lazy2: false,

      ingrediente: {
        producto: "",
        cantidad: 0,
        unidad1: "",
        costomercado: 0,
        unidad2: "",
        observacion: "",
        total: 0
      },

      ingredienteDefault: {
        producto: "",
        cantidad: 0,
        unidad1: "",
        costomercado: 0,
        unidad2: "",
        observacion: "",
        total: 0
      },

      totalGramaje: 0,

      medidas1: [{ medida: "gr" }, { medida: "ml" }, { medida: "Unid" }],

      medidas2: [
        { medida: "Kg" },
        { medida: "Lt" },
        { medida: "C" },
        { medida: "@" }
      ],

      medidas: [
        { medida: "Kg" },
        { medida: "C" },
        { medida: "@" },
        { medida: "Lb" }
      ],

      cuartilla: 2875,

      ingredientes: [],

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"]
    };
  },

  methods: {
    close() {
      this.dialog = false;
    },

    submit() {},

    changeMedida1() {
      if (this.ingrediente.unidad1 == "gr") {
        this.medidas2 = this.medidas;
      }
      if (
        this.ingrediente.unidad1 == "ml" ||
        this.ingrediente.unidad1 == "Lt"
      ) {
        this.medidas2 = this.medidas1;
      }

      if (
        this.ingrediente.unidad1 == "Lt" ||
        this.ingrediente.unidad1 == "ml"
      ) {
        this.medidas2 = [{ medida: "Lt" }];
      }

      if (this.ingrediente.unidad1 == "Unid") {
        this.medidas2 = [{ medida: "Unid" }];
      }
    },

    changeMedida2() {},

    addIngre() {
      if (this.$refs.formIn.validate()) {
        this.$emit("addGramaje", {
          producto: this.ingrediente.producto,
          cantidad: this.ingrediente.cantidad,
          unidad1: this.ingrediente.unidad1,
          costomercado: this.ingrediente.costomercado,
          unidad2: this.ingrediente.unidad2,
          observacion: "",
          total: this.ingrediente.total
        });
        this.ingrediente = this.ingredienteDefault;
        this.$refs.formIn.reset();
        this.dialog = false;
      }
    },

    borrar() {
      this.ingrediente = this.ingredienteDefault;
      this.dialog = false;
    },

    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      //const textTwo = item.abbr.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    }
  },

  computed: {
    calcularTotalGramaje() {
      let total = 0;
      if (
        this.ingrediente.unidad1 == "gr" &&
        this.ingrediente.unidad2 == "Kg"
      ) {
        total =
          (Number(this.ingrediente.cantidad) *
            Number(this.ingrediente.costomercado)) /
          1000;
        this.ingrediente.total = total;
      }
      if (
        this.ingrediente.unidad1 == "gr" &&
        this.ingrediente.unidad2 == "gr"
      ) {
        total =
          Number(this.ingrediente.cantidad) *
          Number(this.ingrediente.costomercado);
        this.ingrediente.total = total;
      }

      if (
        this.ingrediente.unidad1 == "Unid" &&
        this.ingrediente.unidad2 == "Unid"
      ) {
        total =
          (Number(this.ingrediente.cantidad) *
            Number(this.ingrediente.costomercado)) /
          1;
        this.ingrediente.total = total;
      }

      if (
        this.ingrediente.unidad1 == "gr" &&
        this.ingrediente.unidad2 == "Lb"
      ) {
        total =
          (Number(this.ingrediente.cantidad) *
            Number(this.ingrediente.costomercado)) /
          460;
        this.ingrediente.total = total;
      }

      if (this.ingrediente.unidad1 == "gr" && this.ingrediente.unidad2 == "C") {
        total =
          (Number(this.ingrediente.cantidad) *
            Number(this.ingrediente.costomercado)) /
          2875;
        this.ingrediente.total = total;
      }

      if (
        this.ingrediente.unidad1 == "ml" &&
        this.ingrediente.unidad2 == "Lt"
      ) {
        total =
          (Number(this.ingrediente.cantidad) *
            Number(this.ingrediente.costomercado)) /
          1000;
        this.ingrediente.total = total;
      }
      this.ingrediente.total = total.toFixed(2);
      return total.toFixed(2);
    }
  },

  created() {
    this.ingrediente = this.ingredienteDefault;
  }
};
</script>

<style >
.centro {
  text-align: center;
  vertical-align: top;
  border-color: inherit;
}
</style>