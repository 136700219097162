<template>
  <v-dialog persistent v-model="dialog" max-width="550" >
    <template v-slot:activator="{ on }">
      <v-btn fab large v-on="on" color="verde">
        <v-icon large>mdi-plus-thick</v-icon>
      </v-btn>
    </template>

    <v-card dark color="skim">
        <v-card-title class="success py-1">
            <h3 class="white--text">Nuevo Proveedor</h3>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="razon"
                            label="Nombre"
                            filled
                            rounded
                            dense
                            :rules="requiredRule"
                            :error-messages="errorRazon"
                            autocomplete="off"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            v-model="phones"
                            type="number"
                            :min="0"
                            filled
                            rounded
                            dense
                            label="Telf.Fijo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field
                            v-model="celular"
                            type="number"
                            :min="0"
                            filled
                            rounded
                            dense
                            label="Celular"
                            :rules="requiredRule"
                        ></v-text-field>
                    </v-col>
            </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
        <v-btn
            :disabled="!valid"
            @click="submit"
            class="verde white--text"
            :loading="loading"
            rounded
        >
            Guardar
            <v-icon right>send</v-icon>
        </v-btn>
        <v-btn text rounded class="white--text" @click="close">
            Cancelar
        </v-btn>
        </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import { mapActions } from "vuex"

export default {
  props: {
      idu: Number
  },  
  data() {
    return {
      valid: true,
      lazy: false,
      loading: false,
      dialog: false,
      razon: "",
      errorRazon: "",
      phones: "",
      celular: "",
      inputRules: [
        (v) => !!v || "Este Campo es requerido",
        (v) => v.length >= 3 || "Minimo 3 caracteres",
      ],
      requiredRule: [(v) => !!v || "Este Campo es requerido"],
    }
  },

  methods: {
      ...mapActions(["createProvider"]),
      close() {
        this.razon=""
        this.phones=""
        this.celular=""
        this.dialog=false
      },
      submit(){
          this.loading=true
          this.createProvider({
              razon: this.razon,
              phones: this.phones,
              celular: this.celular,
              users_id: this.idu
          }).then(resp=>{
              this.loading = false
              this.$emit("addProvider")
              this.dialog = false
          })
      }
  },
}
</script>

<style scoped>
</style>