<template>
 
  <v-app id="keep">
    <template v-if="loggedIn">
      <Navbar />
    </template>
    <v-main>
      <router-view />
    </v-main>
  </v-app>

</template>

<script>
 import Navbar from "@/components/Navbar.vue"
 import { mapActions } from "vuex"
  export default {
    name: "App",
    components: {
      Navbar
    },
    methods: {
      ...mapActions(["tokenExpires"])
    },
    mounted() {
    },
    computed: {
      loggedIn() {
        return this.$store.getters.isLoggedIn
      }
    }
  }
</script>
<style>
    .pointer {cursor: pointer;}
    .bg {
        width: 100%;
        height: 100%;
        position: inherit;
        background: url( 'assets/images/wood3.jpg') repeat center center;
    }

    .gradient{
      background: rgb(234,242,253);
      background: linear-gradient(320deg, rgba(234,242,253,1) 10%, rgba(255,255,255,1) 100%); 
    }
    .theme--light.v-chip:not(.v-chip--active) {
        background: #83ebb6;
    }
    .theme--dark.v-chip:not(.v-chip--active) {
        background: #83ebb6;
    }
</style>