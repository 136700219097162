<template>
    <v-container fluid class="gradient">
        <v-snackbar v-model="snackbar" :timeout="6000" top color="green" class="title">
            <span>{{texto}}</span>
            <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
        </v-snackbar>
        
        <v-card >
            <v-toolbar color="accent" dark src="@/assets/images/h1.jpg" height="100px">
                <v-toolbar-title >Categorias</v-toolbar-title>
                <v-spacer></v-spacer>
                <NewCategory @categoryAdded="categoryAgregado" />
            </v-toolbar>
        
            <v-layout row justify-start class="mt-3 mb-3">
                <v-btn class="pl-5" small text color="skim" @click="sortBy('id')" slot="activator">
                    <v-icon small left>mdi-folder</v-icon>
                    <span class="caption text-lowercase">Ordenar por ID</span>
                </v-btn>
                <v-btn small text color="skim" @click="sortBy('nombrecat')" slot="activator">
                    <v-icon small left>mdi-folder</v-icon>
                    <span class="caption text-lowercase">Ordenar por Categoria</span>
                </v-btn>
            </v-layout>
            <template v-if="categories!=null">
                <v-row v-for=" (category, index) in categories" :key="category.nombrecat" :class="`px-3 py-2 category ${category.state}`">
                    <v-col cols="2">
                        <div class="caption gris--text">Icono/Color</div>
                        <div>
                            <v-icon dark medium :color="category.color">{{category.icon}}</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="7">
                        <div class="caption gris--text">Nombre de categoria</div>
                        <div>{{ category.nombrecat }}</div>
                    </v-col>
                    <v-col cols="2">
                        <div class="right">
                            <v-chip small :class="`chip ${category.state} white--text my-2 caption`">
                                {{ category.state }}
                            </v-chip>
                        </div>
                    </v-col>
                    <v-col cols="1">
                        <div class="right">
                            <EditCategory
                                :categoria="category"
                                @categoryUpdated="categoryActualizado"
                            />
                        </div>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
            </template>
            <!-- <pre>{{categorias}}</pre> -->
        </v-card>
    </v-container>
</template>

<script>

import NewCategory from '@/components/categorias/NewCategory'
import EditCategory from '@/components/categorias/EditCategory'
import {mapGetters, mapActions} from 'vuex'


export default {
    components: {        
        NewCategory, EditCategory,        
    },
    data() {
        return {
            snackbar: false,
            iduser: 0,
            texto: ''
        }
    },

    computed: {
        ...mapGetters(['categories', 'negocio'])
    },

    methods: {
        ...mapActions(['retrieveNegocio']),
        sortBy(param) {
            /* console.log('sort: '+param)
            this.categories.sort((a, b) => a[param] < b[param] ? -1 : 1) */
        },

        categoryAgregado() {
            //this.categoria = categoria
            //this.categories.push(categoria)
            this.texto = 'Increible! Agregaste un nuevo categoria.'
            this.snackbar = true
        },

        categoryActualizado(categoria) {
            this.texto = 'Excelente! Modificaste una Categoria.'
            this.snackbar = true
        }        
    },

    created() {
        this.retrieveNegocio()
    },

}
</script>

<style scoped>

    .category.activo {
        border-left: 4px solid #83ebb6;
        margin-left: 0px;
    }

    .category.inactivo {
        border-left: 4px solid #f83e70;
        margin-left: 0px;
    }

    .theme--light.v-chip:not(.v-chip--active) {
        background: #83ebb6;
    }

    .theme--light.v-size--small.chip.inactive {
        background: #f83e70;
    }

</style>