import Vue from "vue"
import Vuetify from "vuetify/lib/framework"


Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#F06292",
        primario: "#f5abd0",
        verde: "#83ebb6",
        softverde: "#83ebb6",
        darkverde: "#83ebb6",
        secondary: "#eaf2fd",
        secundario: "#F57C00",
        error: "#f04647",
        errore: "#ff0000",
        cancel: "#E0E0E0",
        info: "#ffb473",
        success: "#FFB74D",
        warning: "#FFC107",
        gray: "#eaf2fd",
        graysuave: "#E9E9E9",
        gris: "#525868",
        blanco: "#FFFFFF",
        accent: "#83ebb6",
        accent2: "#c7e4eb",
        green: "#83ebb6",
        sky: "#06f5e1",
        wood: "#eaf2fd",
        coffeemilk: "#E9E9E9",
        latte: "#525868",
        skim: "#3d2c36",
      },
      dark: {
        primary: "#9a52ab",
      },
    },
  },
  icons: {
    iconfont: ["mdi"],
  },
})
