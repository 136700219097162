<template>
  <v-dialog max-width="700px" persistent v-model="dialog" eager>
    <template v-slot:activator="{ on }">
      <v-btn color="verde" v-on="on" :disabled="disabled" class="py-3 px-6 subtitle-2">
        <v-icon large left>mdi-cash</v-icon>
        <span class="ml-2">Pagar</span>
      </v-btn>
    </template>
    <v-card dark color="skim" >
      <v-card-title>
        <h4>Pagar Pedido</h4>
      </v-card-title>
      
        <v-form class="px-6" ref="form" v-model="valid" :lazy-validation="lazy">
          
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="nitci"
                  label="NIT/CI"
                  autocomplete="off"
                  class="subtitle-2"
                  filled
                  rounded
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="cliente"
                  label="Nombre"
                  dense
                  autocomplete="off"
                  filled
                  rounded
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-select
                  v-model="tipopago"
                  chips
                  clearable
                  return-object
                  :items="pagos"
                  item-text="pago"
                  item-value="pago"
                  v-on:change="changeRoute"
                  filled
                  rounded
                  label="Tipo de Pago"
                  single-line
                  :rules="[v => !!v || 'Tipo de Pago es requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" class="display-2 pb-2">
                <v-card class="text-center pa-1 " color="accent" outlined>
                  <v-chip color="skim" dark>Total (Bs):</v-chip>
                  {{total}}
                </v-card>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="efectivo"
                  label="Efectivo"
                  dense
                  autocomplete="off"
                  filled
                  rounded
                  type="number"
                  :min="0"
                  :rules="requiredRule"
                  :error-messages="efectivoError"
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="cambio"
                  label="Cambio"
                  dense
                  autocomplete="off"
                  type="number"
                  :min="0"
                  readonly
                  filled
                  rounded
                ></v-text-field>
              </v-col>
<!--               <pre>
                  {{ user }}
              </pre> -->
              <v-card-actions>
                <v-btn class="error" color="gray" text @click="close" rounded>
                  Cerrar
                  <v-icon right dark>mdi-cancel</v-icon>
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  @click="submit"
                  color="green"
                  dark
                  :loading="loading"
                  rounded                  
                >
                  Pagar
                  <v-icon dark right>mdi-check</v-icon>
                </v-btn>
              </v-card-actions>
            </v-row>
            <!-- <pre>{{ventas}}</pre> -->
          
        </v-form>
      
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "Pagar",
  props: {
    disabled: Boolean,
    ventas: Array,
    total: Number,
    user: Object
  },
  data() {
    return {
      valid: false,
      lazy: false,
      loading: false,
      dialog: false,
      state: "activo",

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      nitci: "",
      cliente: "",
      pagos: [{ pago: "EFECTIVO" }, { pago: "VISA" }, { pago: "MASTERCARD" }],
      tipopago: null,
      efectivo: 0,
      cambio: 0,
      efectivoError: ""
    };
  },
  methods: {
    ...mapActions(['createVenta']),
    submit() {
      if (this.$refs.form.validate()) {
        if (this.efectivo >= this.total) {
          this.efectivoError = ""
          this.loading = true;

          /* let utilidad = 0;
          this.ventas.forEach(function(valor, indice, array) {
            utilidad = Number(utilidad + valor.prepa * valor.unidades);
          }) */

          let utilidad = this.ventas.reduce( (sum, elem)=> sum+ elem.prepa*elem.unidades, 0)

          //console.log(utilidad)
          this.createVenta({
              nitci: this.nitci,
              total: this.total,
              utilidad: Number(this.total - utilidad),
              tipopago: this.tipopago.pago,
              efectivo: this.efectivo,
              cambio: this.cambio,
              nfactura: 1,
              ventas: this.ventas
            }).then(response=>{

              this.$emit("ventaAdded")
              this.$root.$emit("otraventa", {
                suma: 1
              })

              this.nitci = ""
              this.cliente = ""
               this.tipopago = { pago: "EFECTIVO" }
              this.efectivo = 0
              this.cambio = 0
              this.loading = false
              this.dialog = false
            })

        } else {
          this.valid = true
          this.efectivoError = "Valor ingresado invalido"
        }
      }
    },

    changeRoute(a) {},

    close() {
      this.$refs.form.reset()
      this.dialog = false
    }
  },

  watch: {
    total(value, oldVal) {
      if (value > 0) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },

    efectivo(value, oldVal) {
      this.valid = true;
      this.efectivoError = ""
      if (value >= this.total) {
        this.cambio = Number(this.efectivo - this.total)
      }
    }
  },

  created() {
    //this.disabled = true
    this.tipopago = { pago: "EFECTIVO" }
    if (this.dialog) {
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style>
.v-text-field input {
  font-size: 1.1em;
}
</style>>
