<template>
  <div class="gradient">
    <v-card>
      <v-toolbar
        color="accent"
        dark
        src="@/assets/images/v2.jpg"
        height="100px"
        class="mb-2"
      >
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>Salidas</v-toolbar-title>
        <div class="ml-4 subtitle-1">últimas</div>
        <v-spacer></v-spacer>
        <NuevaSalida @salidaAdded="salidaAgregada" :productos="productos" />
      </v-toolbar>
      <v-divider></v-divider>

      <v-sheet v-if="!salidas"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="px-3 pt-3 pb-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="800"
          type="table-heading, table-thead, table-tbody, table-tfoot"
        ></v-skeleton-loader>
      </v-sheet>

      <Salida v-else
        :salida="salida"
        :index="index"
        v-for="(salida, index) in salidas"
        :key="salida.id"
      />

      <div class="text-center py-2 graysuave" v-if="rows > 7">
        <v-btn x-small rounded color="verde" @click="gotoSalidas">Lista Completa</v-btn>
      </div>

    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="6000"
      top
      color="green"
      class="title"
    >
      <span>{{ mensaje }}</span>
      <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <!--     <pre>
        {{salidas}}
    </pre> -->
  </div>
</template>
<script>
import Salida from "@/components/inventario/Salida.vue";
import NuevaSalida from "@/components/inventario/NuevaSalida.vue";

export default {
  inject: ["theme"],
  name: "salidas",
  components: { Salida, NuevaSalida },
  props: {
    productos: Array,
  },
  data() {
    return {
      salidas: null,
      snackbar: false,
      iduser: 0,
      mensaje: "",
      rows: -1,
    };
  },

  methods: {
    fetchSalidas() {
      this.$store
        .dispatch("retrieve_salidas_limit", 
          { 
            idu: this.iduser 
          })
        .then((response) => {
          this.salidas = response.data;
          this.rows = response.rows;
        })
        .catch((err) => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchSalidas();
          }
        });
    },

    salidaAgregada(salida) {
      this.salidas.push(salida);

      var product = this.productos.find((obj) => obj.id == salida.productos_id);

      if (product != undefined) {
        product.salida =
          Number(product.salida) + Number(salida.cantidad_salida);
        product.saldo = Number(product.saldo) - Number(salida.cantidad_salida);
        this.productos[product.index] = product;
      }

      this.mensaje = "Excelente, Salida realizada";
      this.snackbar = true;
    },

    gotoSalidas(){
      this.$router.push('inventario/salidas')
    }
  },

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }
    this.iduser = user.idu;
    this.fetchSalidas();
  },

  computed: {},
};
</script>

<style scoped></style>
