<template>
  <!-- max-width="900px"  -->
  <v-dialog persistent v-model="dialog" max-width="980px">
    <template v-slot:activator="{ on }">
      <v-btn fab large class="verde" v-on="on">
        <v-icon large>mdi-plus-thick</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="py-1 success white--text">
        Nuevo Plato
      </v-card-title>

      <v-layout justify-center wrap class="py-3">
        <v-flex xs12 md6 sm12>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="nombreplato"
                    label="Nombre del Plato"
                    filled
                    rounded
                    dense
                    :rules="requiredRule"
                    :error-messages="errorPlatoName"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="precioventa"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Precio Venta"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="precioprepa"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Costo"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm9 md9>
                  <v-select
                    class="mt-0"
                    filled
                    rounded
                    clearable
                    v-model="category"
                    :error-messages="errorCats"
                    :items="categorias"
                    item-text="nombrecat"
                    item-value="id"
                    chips
                    return-object
                    label="Categorias"
                    single-line
                    :rules="[v => !!v || 'Categoria es requerida']"
                    required
                    v-on:change="changeRoute"
                  ></v-select>
                </v-flex>

                <!-- <v-flex xs6 sm3 md3>
                  <v-btn rounded large :color="colorNewCat" @click="addNuevaCat">
                    <v-icon large>{{nuevacatIcon}}</v-icon>
                  </v-btn>
                </v-flex> -->

                <!-- <template v-if="verNuevacat">
                  <v-flex xs12 md9>
                    <v-text-field
                      v-model="nuevacat"
                      filled
                      rounded
                      dense
                      label="Nueva Categoria"
                      :rules="requiredRule"
                      :error-messages="errorNuevacat"
                      autocomplete="off"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm3 md3>
                    <v-btn rounded large class="verde" @click="createCat" :loading="loading2">
                      Agregar
                    </v-btn>
                  </v-flex>
                </template> -->
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>

        <v-flex xs12 md6 sm12>
          <v-card class="pa-0" height="440" width="440" color="coffeemilk">
            <vue-cropper
              ref="cropper"
              :img="option.img"
              :output-size="option.size"
              :output-type="option.outputType"
              :info="true"
              :full="option.full"
              :fixed="fixed"
              :fixed-number="fixedNumber"
              :can-move="option.canMove"
              :can-move-box="option.canMoveBox"
              :fixed-box="option.fixedBox"
              :original="option.original"
              :auto-crop="option.autoCrop"
              :auto-crop-width="option.autoCropWidth"
              :auto-crop-height="option.autoCropHeight"
              :center-box="option.centerBox"
              @real-time="realTime"
              :high="option.high"
              @img-load="imgLoad"
              mode="cover"
            ></vue-cropper>
          </v-card>

          <v-card class="pa-0" height="440" color="gris" v-show="verFotos">
            <div
              class="show-preview"
              :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}"
            >
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </v-card>

          <input
            ref="input"
            type="file"
            id="uploads"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="uploadImg($event, 1)"
          />
          <v-btn small @click.prevent="showFileChooser" class="green white--text">
            <v-icon small>mdi-image</v-icon>
          </v-btn>
          <v-btn small @click="refreshCrop">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn small @click="changeScale(3)">
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>
          <v-btn small @click="changeScale(-3)">
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn>
          <v-btn small @click="rotateLeft">
            <v-icon>mdi-rotate-left</v-icon>
          </v-btn>
          <v-btn small @click="rotateRight">
            <v-icon>mdi-rotate-right</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-btn
          :disabled="!valid"
          @click="submit"
          class="green white--text"
          :loading="loading"
          rounded
        >
          Guardar
        </v-btn>
        <v-btn class="skim--text" text @click="close">
          Cancelar              
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="verMensaje" color="success" :timeout="3000" top>{{ imageResultMesage }}</v-snackbar>
  </v-dialog>
</template>

<script>

import { VueCropper } from "vue-cropper"
import { mapGetters } from "vuex"

export default {
  name: "NuevoPlato",
  components: {
    VueCropper
  },
  data() {
    return {
      model: false,
      modelSrc: "",
      crap: false,
      previews: {},
      option: {
        img: "",
        size: 2,
        full: false,
        outputType: "jpeg",
        canMove: true,
        fixedBox: false,
        original: true,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 400,
        autoCropHeight: 400,
        centerBox: false,
        high: true
      },
      show: true,
      fixed: true,
      fixedNumber: [1, 1],

      verMensaje: false,
      imageResultMesage: "",
      valid: true,
      lazy: false,
      loading: false,
      dialog: false,
      category: null,

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      errorPlatoName: "",

      hasImage: false,
      image: null,
      vistaPrevia: true,
      type: "",
      verFotos: false,
      idu: 0,
      id: 0,
      errorCats: "",
      categorias: [],

      verNuevacat: false,
      nuevacat: "",
      idNuevacat: 0,
      errorNuevacat: "",
      nuevacatIcon: "mdi-plus",
      colorNewCat: "accent",
      loading2: false,

      nombreplato: "Pollo Espiedo 1/8",
      precioventa: 17,
      precioprepa: 8,
      foto1: ""
    }
  },

  methods: {
    showFileChooser() {
      this.$refs.input.click()
    },
    startCrop() {
      // start
      this.crap = true
      this.$refs.cropper.startCrop()
    },
    stopCrop() {
      //  stop
      this.crap = false
      this.$refs.cropper.stopCrop()
    },
    clearCrop() {
      // clear
      this.$refs.cropper.clearCrop()
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh()
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    finish(type) {
      // var test = window.open('about:blank')
      // test.document.body.innerHTML = '图片生成中..'
      if (type === "blob") {
        this.$refs.cropper.getCropBlob(data => {
          //console.log(data)
          var img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    realTime(data) {
      this.previews = data
      //console.log(data)
    },
    finish2(type) {
      this.$refs.cropper2.getCropData(data => {
        this.model = true
        this.modelSrc = data
      })
    },
    finish3(type) {
      this.$refs.cropper3.getCropData(data => {
        this.model = true
        this.modelSrc = data
      })
    },
    down(type) {
      // event.preventDefault()
      var aLink = document.createElement("a")
      aLink.download = "demo"

      if (type === "blob") {
        this.$refs.cropper.getCropBlob(data => {
          this.downImg = window.URL.createObjectURL(data)
          aLink.href = window.URL.createObjectURL(data)
          aLink.click()
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.downImg = data
          aLink.href = data
          aLink.click()
        })
      }
    },

    createCat() {
      if (this.nuevacat != "") {
        this.loading2 = true
        var categoryData = {
          nombrecat: this.nuevacat,
          categoria_estado: "active",
          users_id: this.idu,
          icon: "",
          color: ""
        }

        this.$store
          .dispatch("create_category", categoryData)
          .then(response => {
            this.loading2 = false
            this.nuevacat = ""
            categoryData.id = response.data.data
            //this.categories.push(categoryData)
            this.categorias.push(categoryData)
            this.nuevacatIcon = "mdi-plus"
            this.colorNewCat = "accent"
            this.verNuevacat = false
            this.valid = true
            this.errorCats = ""
          })
          .catch(err => {
            if (err.response.status == 422) {
              console.log(err)
            }
          })
      }
    },

    addNuevaCat() {
      this.verNuevacat = !this.verNuevacat
      if (this.verNuevacat) {
        this.nuevacatIcon = "mdi-cancel"
        this.colorNewCat = "coffeemilk"
      } else {
        this.nuevacatIcon = "mdi-plus"
        this.colorNewCat = "accent"
      }
    },

    uploadImg(e, num) {
      // this.option.img
      var file = e.target.files[0]
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Solo archivos .gif,jpeg,jpg,png,bmp son permitidos")
        return false
      }
      var reader = new FileReader()
      reader.onload = e => {
        let data
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        if (num === 1) {
          this.option.img = data
        } else if (num === 2) {
          this.example2.img = data
        }
      }
      //base64
      // reader.readAsDataURL(file)
      //blob
      //this.valid = false
      //this.verFotos = true
      reader.readAsArrayBuffer(file)
    },

    submit() {
      if (this.categorias.length == 0 && !this.verNuevacat) {
        this.valid = true
        this.errorCats = "Debe seleccionar al menos una categoria"
      } else {
        this.errorCats = ""
        this.valid = false
      }

      if (this.$refs.form.validate()) {
        if (!this.valid) {
          this.loading = true

          if (this.option.img != "") {
            this.$refs.cropper.getCropBlob(data => {
              let iData = {
                image: data,
                type: "jpg"
              }
              this.$store
                .dispatch("image_upload", iData)
                .then(response => {
                  setTimeout(this.createPlato(response), 3000)
                })
                .catch(err => {
                  console.log(err)
                })
            })
          } else {
            this.createPlato("")
          }
        }
      }
    },

    createPlato(respuesta) {
      this.foto1 = respuesta
      //this.plato.users_id = this.idu

      this.$store
        .dispatch("create_plato", {
          nombreplato: this.nombreplato,
          precioventa: this.precioventa,
          precioprepa: this.precioprepa,
          foto1: this.foto1,
          estadoplato: 1,
          category: this.category.nombrecat
        })
        .then(response => {
          this.$emit("platoAdded")

          this.nombreplato = ""
          this.precioventa = 0
          this.precioprepa = 0
          this.option.img = ""
          this.hasImage = false
          this.image = null
          this.loading = false
          this.verNuevacat = ""
          this.verNuevacat = false
          this.idNuevacat = 0
          this.errorNuevacat = ""
          this.dialog = false
        })
        .catch(errore => {
          if (errore.response.status == 422) {
            this.errorPlatoName = errore.response.data.message.nombreplato[0]
            this.errorPlatoName = this.errorPlatoName.replace(
              "nombreplato",
              "Nombre de Plato"
            )
            this.valid = true
            this.loading = false
          }
        })
    },

    close() {
      this.nombreplato = ""
      this.precioventa = 0
      this.precioprepa = 0
      this.option.img = ""
      this.hasImage = false
      this.image = null
      this.categorias = []
      this.verFotos = false
      this.verNuevacat = ""
      this.verNuevacat = false
      this.idNuevacat = 0
      this.errorNuevacat = ""
      this.dialog = false
    },

    changeRoute(a) {
      //this.valid = true
      this.errorCats = ""
      /* if (this.categorias.length > 0) {
        this.nuevacatIcon = "mdi-plus"
        this.colorNewCat = "accent"
        this.verNuevacat = false
      } */
    },

    imgLoad(msg) {
      //console.log(msg)
    },

    checkPlatoName(value) {
      let fdata = {
        nombreplato: value,
        idu: this.idu
      }

      this.$store
        .dispatch("name_plato", fdata)
        .then(response => {
          //console.log(response.status)
          if (response.status == 200) {
            this.errorPlatoName = ""
            this.valid = true
          }
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorPlatoName = "Nombre plato ya existe"
            this.valid = true
          }
        })
    },

    chekCateName(valor) {
      this.$store
        .dispatch("nameCategory", {
          nombrecat: valor,
          idu: this.idu
        })
        .then(response => {
          this.errorNuevacat = ""
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorNuevacat = err.response.data.message.nombrecat[0]
            this.errorNuevacat = this.errorNuevacat.replace(
              "nombrecat",
              "Nombre de Categoria"
            )
            this.valid = false
          }
        })
    }
  },

  watch: {
    /* nombreplato(value, oldVal) {
      if (value) {
        //this.nombreplato = value
        this.checkPlatoName(value)
      }
    },

    nuevacat(value, oldvalue) {
      if (value != "") {
        this.chekCateName(value)
      }
    } */
    dialog(value, oldvalue){
      if (value){
        this.categorias = JSON.parse(this.negocio.categories)
      }
    }
  },

  computed: {
    ...mapGetters(['negocio'])
  },

  created() {
    let user = this.$store.getters.authUser
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }

    /* this.nombreplato = ""
    this.precioventa = 0
    this.precioprepa = 0 */
    this.option.img = ""
    this.hasImage = false
    this.image = null
    this.idu = user.id
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none
}
</style>

