<template>
  <div class="gradient">
    <v-row justify="center" class="mt-0 ml-0 mr-0">
      <v-snackbar v-model="snackbar" :timeout="6000" top color="green" class="title">
        <span>{{mensaje}}</span>
        <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
      </v-snackbar>

      <v-container class="grey lighten-5">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <Productos @productoAdded="agregarProducto" :productos="productos" />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-card class="pa-2" outlined tile>
              <Entradas :productos="productos" />
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-card class="pa-2" outlined tile>
              <Salidas :productos="productos" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>

<script>

import Productos from "@/components/inventario/Productos"
import Entradas from "@/components/inventario/Entradas"
import Salidas from "@/components/inventario/Salidas"

export default {
  components: {
    Productos,
    Entradas,
    Salidas
  },

  data() {
    return {
      snackbar: false,
      iduser: 0,
      mensaje: "",
      categorias: [],
      productos: null
    }
  },

  methods: {
    fetchCategorias() {
      this.$store
        .dispatch("retrieveCategorias", { idu: this.iduser })
        .then(response => {
          this.categorias = response
        })
        .catch(err => {
          if (err == 401) {
            this.$store.dispatch("refesh_token")
            this.fetchCategorias()
          }
        })
    },

    fetchProductos() {
      this.$store
        .dispatch("retrieve_productos", { idu: this.iduser })
        .then(response => {
          this.productos = response
        })
        .catch(err => {
          //console.log(err)
          if (err == 401) {
            this.$store.dispatch("refesh_token")
            this.fetchProductos()
          }
        })
    },

    agregarProducto(producto) {
      this.productos.push(producto)
      this.mensaje = "Excelente, creaste un Producto"
      this.snackbar = true
    }
  },

  created() {
    let user = this.$store.getters.authUser
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }
    this.iduser = user.idu

    this.fetchCategorias()
    this.fetchProductos()
  }
}
</script>

<style scoped>
</style>