<template>
  <v-dialog persistent v-model="dialog" max-width="980px">
    <template v-slot:activator="{ on }">
      <v-btn fab large v-on="on" class="verde">
        <v-icon large>mdi-plus-thick</v-icon>
      </v-btn>
    </template>
    <v-card dark color="skim">
      <v-card-title class="py-1">
        <h3 class="gray--text">Nuevo Producto</h3>
      </v-card-title>
      <v-layout justify-center wrap>
        <v-flex xs12 md6 sm12>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-text-field
                    v-model="name"
                    label="Nombre del Producto"
                    filled
                    rounded
                    dense
                    :rules="requiredRule"
                    :error-messages="errorProductoName"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="entrada"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Entradas"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    v-model="salida"
                    type="number"
                    :min="0"
                    filled
                    rounded
                    dense
                    label="Salidas"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 md12>
                  <v-text-field
                    v-model="saldo"
                    label="Saldo"
                    filled
                    rounded
                    dense
                    :min="0"
                    :rules="requiredRule"
                    autocomplete="off"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm9 md9>
                  <v-select
                    class="mt-0"
                    filled
                    rounded
                    clearable
                    v-model="unids"
                    :error-messages="errorUnidades"
                    :items="unidades"
                    item-text="uname"
                    item-value="uname"
                    chips
                    return-object
                    label="Unidad"
                    single-line
                    :rules="[v => !!v || 'Unidad es requerida']"
                    required
                    v-on:change="changeRoute2"
                  ></v-select>
                </v-flex>

                <v-flex xs12 sm9 md9>
                  <v-select
                    class="mt-0"
                    filled
                    rounded
                    clearable
                    v-model="grupo"
                    :error-messages="errorCats"
                    :items="grupos"
                    item-text="gname"
                    item-value="id"
                    chips
                    return-object
                    label="Categoria"
                    single-line
                    :rules="[v => !!v || 'Categoria es requerida']"
                    required
                    v-on:change="changeRoute"
                  ></v-select>
                </v-flex>

                <v-flex xs6 sm3 md3>
                  <v-btn rounded large :color="colorNewCat" @click="addNuevaCat">
                    <v-icon large>{{nuevacatIcon}}</v-icon>
                  </v-btn>
                </v-flex>

                <template v-if="verNuevacat">
                  <v-flex xs12 md9>
                    <v-text-field
                      v-model="nuevacat"
                      filled
                      rounded
                      dense
                      label="Nueva Categoria"
                      :rules="requiredRule"
                      :error-messages="errorNuevacat"
                      autocomplete="off"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm3 md3>
                    <v-btn rounded large class="verde" @click="createCat" :loading="loading2">
                      <v-icon large>done</v-icon>
                    </v-btn>
                  </v-flex>
                </template>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
        <v-flex xs12 md6 sm12 class="py-1">
          <v-card class="pa-0" height="440" width="440" color="coffeemilk">
            <vue-cropper
              ref="cropper"
              :img="option.img"
              :output-size="option.size"
              :output-type="option.outputType"
              :info="true"
              :full="option.full"
              :fixed="fixed"
              :fixed-number="fixedNumber"
              :can-move="option.canMove"
              :can-move-box="option.canMoveBox"
              :fixed-box="option.fixedBox"
              :original="option.original"
              :auto-crop="option.autoCrop"
              :auto-crop-width="option.autoCropWidth"
              :auto-crop-height="option.autoCropHeight"
              :center-box="option.centerBox"
              @real-time="realTime"
              :high="option.high"
              @img-load="imgLoad"
              mode="cover"
            ></vue-cropper>
          </v-card>

          <v-card class="pa-0" height="440" color="gris" v-show="verFotos">
            <div
              class="show-preview"
              :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}"
            >
              <div :style="previews.div">
                <img :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </v-card>

          <input
            ref="input"
            type="file"
            id="uploads"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="uploadImg($event, 1)"
          />
          <v-btn small @click.prevent="showFileChooser" class="green white--text">
            <v-icon small>mdi-image</v-icon>Imagen
          </v-btn>
          <v-btn small @click="refreshCrop">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn small @click="changeScale(3)">
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>
          <v-btn small @click="changeScale(-3)">
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn>
          <v-btn small @click="rotateLeft">
            <v-icon>mdi-rotate-left</v-icon>
          </v-btn>
          <v-btn small @click="rotateRight">
            <v-icon>mdi-rotate-right</v-icon>
          </v-btn>

        </v-flex>
        <v-flex xs12>
          <v-card-actions>
            <v-btn
              :disabled="!valid"
              @click="submit"
              class="verde white--text"
              :loading="loading"
              rounded
            >
              Guardar
            </v-btn>
            <v-btn text rounded class="white--text" @click="close">
              Cancelar              
            </v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
    </v-card>
    <v-snackbar v-model="verMensaje" color="success" :timeout="3000" top>{{ imageResultMesage }}</v-snackbar>
  </v-dialog>
</template>

<script>
import { VueCropper } from "vue-cropper"

export default {
  name: "NuevoProducto",
  props: {
    grupos: Array
  },
  components: {
    VueCropper
  },
  data() {
    return {
      model: false,
      modelSrc: "",
      crap: false,
      previews: {},
      option: {
        img: "",
        size: 2,
        full: false,
        outputType: "jpeg",
        canMove: true,
        fixedBox: false,
        original: true,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 400,
        autoCropHeight: 400,
        centerBox: false,
        high: true
      },
      show: true,
      fixed: true,
      fixedNumber: [1, 1],

      verMensaje: false,
      imageResultMesage: "",
      valid: true,
      lazy: false,
      loading: false,
      dialog: false,

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      errorProductoName: "",

      hasImage: false,
      image: null,
      vistaPrevia: true,
      type: "",
      verFotos: false,
      errorCats: "",
      errorUnidades: "",
      unidades: [
        { uname: "gr" },
        { uname: "ml" },
        { uname: "Kg" },
        { uname: "C" },
        { uname: "@" },
        { uname: "Lt" },
        { uname: "Unid" },
        { uname: "Lb" }
      ],

      verNuevacat: false,
      nuevacat: "",
      idNuevacat: 0,
      errorNuevacat: "",
      nuevacatIcon: "mdi-plus",
      colorNewCat: "accent",
      loading2: false,

      name: "",
      marca: "",
      picture: null,
      grupo: null,
      entrada: 0,
      salida: 0,
      saldo: 0,
      unids: "",
      idu: 0,
      id: 0
    }
  },

  methods: {
    showFileChooser() {
      this.$refs.input.click()
    },
    startCrop() {
      // start
      this.crap = true
      this.$refs.cropper.startCrop()
    },
    stopCrop() {
      //  stop
      this.crap = false
      this.$refs.cropper.stopCrop()
    },
    clearCrop() {
      // clear
      this.$refs.cropper.clearCrop()
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh()
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    finish(type) {
      // var test = window.open('about:blank')
      // test.document.body.innerHTML = '图片生成中..'
      if (type === "blob") {
        this.$refs.cropper.getCropBlob(data => {
          //console.log(data)
          var img = window.URL.createObjectURL(data)
          this.model = true
          this.modelSrc = img
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.model = true
          this.modelSrc = data
        })
      }
    },
    realTime(data) {
      this.previews = data
      //console.log(data)
    },
    finish2(type) {
      this.$refs.cropper2.getCropData(data => {
        this.model = true
        this.modelSrc = data
      })
    },
    finish3(type) {
      this.$refs.cropper3.getCropData(data => {
        this.model = true
        this.modelSrc = data
      })
    },
    down(type) {
      // event.preventDefault()
      var aLink = document.createElement("a")
      aLink.download = "demo"

      if (type === "blob") {
        this.$refs.cropper.getCropBlob(data => {
          this.downImg = window.URL.createObjectURL(data)
          aLink.href = window.URL.createObjectURL(data)
          aLink.click()
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.downImg = data
          aLink.href = data
          aLink.click()
        })
      }
    },

    createCat() {
      if (this.nuevacat != "") {
        this.loading2 = true
        var categoryData = {
          gname: this.nuevacat,
          users_id: this.idu,
          gicon: "",
          gcolor: ""
        }

        this.$store
          .dispatch("create_grupo", categoryData)
          .then(response => {
            this.loading2 = false
            this.nuevacat = ""
            categoryData.id = response.data.data
            this.grupos.push(categoryData)
            this.grupo = categoryData
            //this.categorias.push(categoryData)
            this.nuevacatIcon = "mdi-plus"
            this.colorNewCat = "accent"
            this.verNuevacat = false
            this.valid = true
            this.errorCats = ""
          })
          .catch(err => {
            if (err.response.status == 422) {
              console.log(err)
            }
          })
      }
    },

    addNuevaCat() {
      this.verNuevacat = !this.verNuevacat
      if (this.verNuevacat) {
        this.nuevacatIcon = "mdi-cancel"
        this.colorNewCat = "coffeemilk"
      } else {
        this.nuevacatIcon = "add"
        this.colorNewCat = "accent"
      }
    },

    uploadImg(e, num) {
      var file = e.target.files[0]
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Solo archivos .gif,jpeg,jpg,png,bmp son permitidos")
        return false
      }
      var reader = new FileReader()
      reader.onload = e => {
        let data
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        if (num === 1) {
          this.option.img = data
        } else if (num === 2) {
          this.example2.img = data
        }
      }
      reader.readAsArrayBuffer(file)
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true

        if (this.option.img != "") {
          this.$refs.cropper.getCropBlob(data => {
            let iData = {
              image: data,
              type: "jpg"
            }
            this.$store
              .dispatch("image_upload", iData)
              .then(response => {
                setTimeout(this.createProducto(response), 3000)
              })
              .catch(err => {
                console.log(err)
              })
          })
        } else {
          this.createProducto("")
        }
      }
    },

    createProducto(respuesta) {
      this.picture = respuesta
      if (this.$refs.form.validate()) {
        let fData = {
          name: this.name,
          entrada: this.entrada,
          picture: this.picture,
          salida: this.salida,
          saldo: this.saldo,
          state: "active",
          users_id: this.idu,
          grupos_id: this.grupo.id,
          gname: this.grupo.gname,
          unids: this.unids.uname,
          saldo: this.entrada
        }

        //console.log(fData)
        this.$store
          .dispatch("create_producto", fData)
          .then(response => {
            //console.log('producto creado, id:'+response.data.data)
            fData.id = response.data.data
            this.$emit("productoAdded", fData)
            this.close()
          })
          .catch(errore => {
            this.valid = true
            this.loading = false
          })
      }
    },

    close() {
      this.name = ""
      this.entrada = 0
      this.salida = 0
      this.saldo = 0
      this.option.img = ""
      this.hasImage = false
      this.image = null
      this.grupo = null
      this.verFotos = false
      this.verNuevacat = ""
      this.verNuevacat = false
      this.idNuevacat = 0
      this.errorNuevacat = ""
      this.picture = null
      this.loading = false
      this.dialog = false
    },

    changeRoute(a) {},

    changeRoute2() {},

    imgLoad(msg) {
      //console.log(msg)
    },

    checkProductoName(value) {
      let fdata = {
        name: value,
        idu: this.idu
      }

      this.$store
        .dispatch("check_pname", fdata)
        .then(response => {
          if (response.status == 200) {
            this.errorProductoName = ""
            this.valid = true
          }
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorProductoName = "Nobre de Producto ya existe"
            this.valid = true
          }
        })
    },

    chekCateName(valor) {
      this.$store
        .dispatch("check_gname", {
          gname: valor,
          idu: this.idu
        })
        .then(response => {
          this.errorNuevacat = ""
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorNuevacat = "Nombre Categoria ya existe"
            this.valid = false
          }
        })
    }
  },

  watch: {
    name(value, oldVal) {
      if (value) {
        this.checkProductoName(value)
      }
    },

    nuevacat(value, oldvalue) {
      if (value != "") {
        this.chekCateName(value)
      }
    }
  },

  created() {
    let user = this.$store.getters.authUser
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }

    this.name = ""
    this.marca = ""
    this.entrada = 0
    this.salida = 0
    this.saldo = 0
    this.unids = ""
    this.picture = null

    this.option.img = ""
    this.hasImage = false
    this.image = null
    this.idu = user.idu
    this.loading = false
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}

.cardbg {
  background: url("../../assets/images/h1.jpg") no-repeat center center;
  background-size: cover;
}
</style>