<template>
  <v-container fluid class="gradient px-4 py-8">
    <v-snackbar v-model="snackbar" :timeout="6000" top color="green" class="title">
      <span>{{texto}}</span>
      <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>

    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-card>
          <v-card-title>Cotizar Costo de Platos</v-card-title>
          <v-stepper v-model="e1" :vertical="vertical">
            <v-stepper-step :complete="e1 > 1" step="1">Selecionar un Plato</v-stepper-step>
            <Paso1 :usuario="usuario" @continuar="goto1" @cancelar="cancelar" />
            <v-stepper-step :complete="e1 > 2" step="2">Selecionar una Receta</v-stepper-step>
            <Paso2 :usuario="usuario" :plato="plato" @continuar="goto" @volver="goback" />
            <v-stepper-step :complete="e1 > 3" step="3">Calcular</v-stepper-step>
            <Paso3
              :receta="receta"
              @continuar="goback2"
              @detalle="show_ingredientes"
              @reset="ingredientes=null"
            />
          </v-stepper>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-card>
          <v-card-title>Estimado en Insumos</v-card-title>
          <v-card-text>
 
            <v-divider></v-divider>
            <v-row no-gutters class="pb-1 pt-1">
              <v-col cols="1" sm="1" md="1" class="text-center">
                <div class="py-0 font-weight-light">##</div>
              </v-col>
              <v-col cols="5" sm="5" md="5" class="px-0 text-center">
                <div class="font-weight-thin">Producto</div>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="px-0 text-center">
                <div class="font-weight-thin">Cantidad/Unidad</div>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <div class="font-weight-thin text-center">Opt.</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <template v-if="numero>0">
              <v-sheet
                v-if="!ingredientes"
                :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
                class="px-3 pt-3 pb-3"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="800"
                  type="table-heading, table-thead, table-tbody, table-tfoot"
                ></v-skeleton-loader>
              </v-sheet>
              <div v-else v-for="(ingrediente, index) in ingredientes" :key="ingrediente.id">
                <v-row no-gutters class="pb-1 pt-1">
                  <v-col cols="1" sm="1" md="1" class="text-center">
                    <div class="py-1 font-weight-light">{{ index + 1 }}.-</div>
                  </v-col>
                  <v-col cols="5" sm="5" md="5" class="px-1 text-left">
                    <div class="py-0 font-weight-light">{{ingrediente.producto}}</div>
                  </v-col>
                  <v-col cols="4" sm="4" md="4" class="px-1 text-center">
                    <v-chip small color="green">
                      <strong v-html="ingrediente.necesario.cant2"></strong>
                    </v-chip>
                    <span v-html="ingrediente.necesario.medida2" class="px-1"></span>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="text-center">
                    <template v-if="ingrediente.estado=='reserved'">
                      <Salida :ingrediente="ingrediente" @cambiarEstado="changeState" />
                    </template>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import Paso1 from "@/components/cotizador/Paso1";
import Paso2 from "@/components/cotizador/Paso2";
import Paso3 from "@/components/cotizador/Paso3";
import Salida from "@/components/cotizador/Salida";

export default {
  inject: ["theme"],
  name: "Cotizador",
  components: {
    Paso1,
    Paso2,
    Paso3,
    Salida
  },
  data() {
    return {
      snackbar: false,
      loading: false,
      texto: "",
      vertical: true,

      usuario: null,
      ingredientes: null,
      ingres: null,
      e1: 1,
      plato: null,
      receta: null,
      numero: 0
    };
  },

  methods: {
    fetchRecetas() {
      if (this.plato != null) {
        this.$store
          .dispatch("retrieve_recetas_plato", {
            id: this.plato.id
          })
          .then(response => {
            this.recetas = response;
            /*           this.recetas.sort((a, b) =>
            a["producto"] < b["producto"] ? -1 : 1
          ); */
          })
          .catch(err => {
            console.log(err);
            if (err == 401) {
              this.$store.dispatch("refesh_token");
              this.fetchRecetas();
            }
          });
      }
    },

    volver() {
      this.$router.go(-1);
    },

    changeRecetas() {
      //this.fetchRecetas();
    },

    fetchIngredientes() {
      if (this.receta != null) {
        this.$store
          .dispatch("retrieve_ingredientes", {
            id: this.receta.id
          })
          .then(response => {
            let respuesta = response;

            if (this.receta != null) {
              let numero = this.numero;
              respuesta = response.map(function(ingredient) {
                let cant2 = 0;

                if (ingredient.unidad1 === "gr" && ingredient.unidad2 === "C") {
                  cant2 = ((ingredient.cantidad * numero) / 2875).toFixed(2);
                }
                if (
                  ingredient.unidad1 === "gr" &&
                  ingredient.unidad2 === "Kg"
                ) {
                  cant2 = ((ingredient.cantidad * numero) / 1000).toFixed(2);
                }
                if (
                  ingredient.unidad1 === "gr" &&
                  ingredient.unidad2 === "Lb"
                ) {
                  cant2 = ((ingredient.cantidad * numero) / 460).toFixed(2);
                }
                if (
                  ingredient.unidad1 === "ml" &&
                  ingredient.unidad2 === "Lt"
                ) {
                  cant2 = ((ingredient.cantidad * numero) / 1000).toFixed(2);
                }
                if (
                  ingredient.unidad1 === "Unid" &&
                  ingredient.unidad1 === "Unid"
                ) {
                  cant2 = (ingredient.cantidad * numero).toFixed(0);
                }

                let ingre = {
                  id: ingredient.id,
                  producto: ingredient.producto,
                  cantidad: ingredient.cantidad,
                  unidad1: ingredient.unidad1,
                  unidad2: ingredient.unidad2,
                  costomercado: ingredient.costomercado,
                  total: ingredient.total,
                  recetas_id: ingredient.recetas_id,
                  estado: "reserved",
                  necesario: {
                    cant1: (ingredient.cantidad * numero).toFixed(2),
                    medida1: ingredient.unidad1,
                    cant2: cant2,
                    medida2: ingredient.unidad2
                  }
                };
                return ingre;
              });

              this.ingredientes = respuesta;
            }
          })
          .catch(err => {
            console.log(err);
            if (err == 401) {
              this.$store.dispatch("refesh_token");
              this.fetchIngredientes();
            }
          });
      }
    },

    goto(receta) {
      this.receta = receta;
      this.e1 = 3;
    },

    goback(param) {
      this.e1 = param;
      this.receta = null;
      this.ingredientes = null;
    },

    goto1(plato) {
      this.plato = plato;
      this.e1 = 2;
    },

    goback2() {
      this.e1 = 2;
    },

    cancelar() {
      this.plato = null;
      this.receta = null;
      this.ingredientes = null;
      this.numero = 0;
    },

    show_ingredientes(numero) {
      this.numero = numero;
      this.fetchIngredientes();
      this.e1 = 3;
    },

    changeState(param) {
      var elementsIndex = this.ingredientes.findIndex(
        element => element.id == param
      );      
      var objecto = this.ingredientes[elementsIndex];
      objecto.estado = "taked";
      //this.ingredientes[elementsIndex] = objecto;
    },

  },

  computed: {
    /*     getIngredientes() {
      if (this.receta != null) {
        let numero = this.numero;
        let res = this.ingredientes.map(function(ingredient) {
          let cant2 = 0;
          if (ingredient.unidad1 === "gr" && ingredient.unidad2 === "C") {
            cant2 = ((ingredient.cantidad * numero) / 2875).toFixed(2);
          }
          if (ingredient.unidad1 === "gr" && ingredient.unidad2 === "Kg") {
            cant2 = ((ingredient.cantidad * numero) / 1000).toFixed(2);
          }
          if (ingredient.unidad1 === "gr" && ingredient.unidad2 === "Lb") {
            cant2 = ((ingredient.cantidad * numero) / 460).toFixed(2);
          }
          if (ingredient.unidad1 === "ml" && ingredient.unidad2 === "Lt") {
            cant2 = ((ingredient.cantidad * numero) / 1000).toFixed(2);
          }
          if (ingredient.unidad1 === "Unid" && ingredient.unidad1 === "Unid") {
            cant2 = (ingredient.cantidad * numero).toFixed(0);
          }

          ingredient.necesario = {
            cant1: (ingredient.cantidad * numero).toFixed(2),
            medida1: ingredient.unidad1,
            cant2: cant2,
            medida2: ingredient.unidad2
          };
          ingredient.estado = "reserved";

          return ingredient;
        });
        //console.log(res)
        return res;
      }
    } */
  },

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }
    this.usuario = user;
  }

  /*   watch: {
    plato(newValue, oldValue) {
      if (newValue != null) {
        this.fetchRecetas();
      }
    }
  } */
};
</script>

<style lang="scss" scoped>
.ventana {
  height: 80vh;
}

.scroll {
  overflow-y: auto;
  height: 80vh;
}
</style>