<template>
  <v-container class="fill-height py-7 gradient" fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-card>
          <v-card-title>
            Edita tu cuenta
          </v-card-title>
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md6>
                  <v-text-field
                    filled
                    rounded
                    v-model.trim="nombres"
                    label="Nombres*"
                    :error-messages="nombresErrors"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    filled
                    rounded
                    v-model="cel_phone"
                    label="Telefono*"
                    :error-messages="celPhoneErrors"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    filled
                    rounded
                    v-model.trim="email"
                    label="Email*"
                    :error-messages="emailErrors"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    filled
                    rounded
                    v-model.trim="password"
                    label="Nuevo Password*"
                    type="password"
                    :error-messages="passwordErrors"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md6>
                  <v-text-field
                    filled
                    rounded
                    v-model.trim="rePassword"
                    label="Repetir Nuevo Password*"
                    type="password"
                    :error-messages="rePasswordErrors"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 text-xs-right>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      rounded
                      class="green white--text"
                      @click="submit"
                      :disabled="$v.$invalid"
                      :loading="loading"
                    >
                      Guardar
                      <v-icon dark right>check</v-icon>
                    </v-btn>
                    <v-btn text class="skim--text" @click="volver">
                      Cancelar                      
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 class="pb-12">
        <v-card outlined tile>
          <v-img v-if="picture!=null" :src="picture" height="450px"></v-img>
          <v-img v-else src="@/assets/images/profile.png" height="450px"></v-img>
        </v-card>
        <EditarImagen @imageChanged="imagenCambiada" />
      </v-flex>
    </v-layout>
    <v-snackbar v-model="verMensaje" color="success" :timeout="3000" top>{{ resultMesage }}</v-snackbar>
  </v-container>
</template>

<script>

import EditarImagen from "@/components/EditarImagen.vue";

export default {
  name: "Profile",
  components: {
    EditarImagen
  },
  data() {
    return {
      valid: true,
      loading: false,
      loadingImage: false,

      idu: 0,
      nombres: "",
      email: "",
      cel_phone: "",
      password: "",
      rePassword: "",
      picture: "",

      errorEmail: "",
      resultMesage: "",
      verMensaje: false,
      password: "",
      rePassword: "",
      nombres: "",
      cel_phone: "",
      email: "",
    }
  },

  methods: {
    volver() {
      this.$router.go(-1);
    },

    checkEmail(value) {
      this.$store
        .dispatch("existeEmail2", {
          email: value,
          idu: this.idu
        })
        .then(() => {
          this.errorEmail = "";
          //this.valid = true
        })
        .catch(err => {
          //console.log(err)
          if (err.response.status == 422) {
            this.errorEmail = err.response.data.message.email[0];
            //this.valid = false;
          }
        })
    },

    submit: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error("validation erros");
      } else {
        this.loading = true;
        const userData = {
          name: this.nombres,
          email: this.email,
          cel_phone: this.cel_phone,
          password: this.password,
          idu: this.idu,
          picture: this.picture
        };

        this.$store
          .dispatch("update_user", userData)
          .then(response => {
            this.loading = false;
            /*               this.password = ''
              this.rePassword = '' */
            this.resultMesage = "Datos de perfil actualizados con exito";
            this.verMensaje = true;
            this.$root.$emit("cambiarNombre", {
              nombres: this.nombres,
              picture: this.picture
            });
          })
          .catch(err => {
            //if (err.response.status == 422) {
            console.log(err);
            //}
          });
      }
    },

    onPickFile() {
      this.$refs.fileInput.click();
    },

    getImageUrl() {
      //let imgUrl = ''
      this.$store
        .dispatch("imagen", {
          imageName: this.imageName
        })
        .then(response => {
          //console.log("getImageUrl:" + response);
          this.picture = response.data;
        })
        .catch(err => {
          console.log(err);
        });
    },

    imagenCambiada(imagenUrl) {
      this.picture = imagenUrl;

      const userData = {
        name: this.nombres,
        email: this.email,
        cel_phone: this.cel_phone,
        picture: imagenUrl,
        idu: this.idu
      };

      this.$store
        .dispatch("update_user_image", userData)
        .then(() => {
          this.$store.dispatch("setUser", userData);
          this.loadingImage = false;
          this.verMensaje = true;
          this.resultMesage = "Foto cambiada con exito";
          this.picture = imagenUrl;
          this.$root.$emit("cambiarNombre", {
            nombres: this.nombres,
            picture: this.picture
          });
          //to-do update sesion
        })
        .catch(erri => {
          console.log(erri);
        });
    }
  },
  created: function() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }
    //console.log(user)
    this.idu = user.idu;
    this.email = user.email;
    this.cel_phone = user.cel_phone;
    this.nombres = user.name;
    this.picture = user.picture;
  },

  watch: {
    email(value) {
      this.checkEmail(value);
    }
  }
}
</script>