<template>
  <div class="gradient">
    <v-row justify="center" class="mt-0 ml-0 mr-0">
      <v-snackbar v-model="snackbar" :timeout="6000" top color="green" class="title">
        <span>{{ mensaje }}</span>
        <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
      </v-snackbar>

      <v-container class="my-2">
        <v-sheet
          v-if="!platos"
          :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
          class="px-3 pt-3 pb-3"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="800"
            type="table-heading, table-thead, table-tbody, table-tfoot"
          ></v-skeleton-loader>
        </v-sheet>

        <v-card v-else>
          <v-toolbar color="accent" dark src="@/assets/images/h1.jpg" height="100px">            
            <v-toolbar-title>Mis Platos</v-toolbar-title>
            <v-spacer></v-spacer>
            <NuevoPlato @platoAdded="platoAgregado" />
          </v-toolbar>

          <v-layout row justify-start class="py-3">
            <v-btn class="pl-5" small text color="skim" @click="sortBy('id')" slot="activator">
              <v-icon small left>mdi-folder</v-icon>
              <span class="caption text-lowercase">Ordenar por ID</span>
            </v-btn>
            <v-btn small text color="skim" @click="sortBy('nombreplato')" slot="activator">
              <v-icon small left>mdi-folder</v-icon>
              <span class="caption text-lowercase">Ordenar por Nombre</span>
            </v-btn>
          </v-layout>

          <v-card v-for="(plato, index) in platos" :key="plato.id + index">
            <v-layout row wrap :class="`pa-3 plato ${estadoPlato(plato.estadoplato)}`">
              <v-flex xs md1>
                <div class="caption secundario--text"></div>
                <v-avatar color="secondary" size="62">
                  <img v-if="plato.foto1 != null" :src="plato.foto1" :alt="plato.nombreplato" />
                  <img v-else src="@/assets/images/food.jpg" :alt="plato.nombreplato" />
                </v-avatar>
              </v-flex>
              <v-flex xs pl-2 md3>
                <div class="caption gris--text">Nombre de Plato</div>
                <div>{{ plato.nombreplato }}</div>
              </v-flex>
              <v-flex xs pl-2 md1>
                <div class="caption gris--text">Categoria</div>
                <div>{{ plato.category }}</div>
              </v-flex>
              <v-flex xs md1>
                <div class="caption gris--text">Precio Venta</div>
                <div>{{ plato.precioventa }}</div>
              </v-flex>
              <v-flex xs md2>
                <div class="caption gris--text">Costo</div>
                <div>{{ plato.precioprepa }}</div>
              </v-flex>
              <v-flex xs md1>
                <v-switch
                  color="celeste"
                  v-model="plato.estadoplato"
                  @change="toggle($event, plato.id)"
                ></v-switch>
              </v-flex>
              <v-flex xs md1>
                <div class="caption gris--text">&nbsp;</div>
                  <EditPlato
                    :plato="plato"
                    @platoUpdated="platoActualizado"
                  />                
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-card>
        </v-card>
        <!-- <pre>{{platos}}</pre> -->
      </v-container>
    </v-row>
  </div>
</template>

<script>
import NuevoPlato from "@/components/platos/NuevoPlato.vue"
import EditPlato from "@/components/platos/EditPlato.vue"
import { mapActions, mapGetters } from "vuex"

export default {
  inject: ["theme"],
  components: {
    NuevoPlato,
    EditPlato
  },

  data() {
    return {
      snackbar: false,
      iduser: 0,
      mensaje: ""
    }
  },

  methods: {
    ...mapActions(['retrieveNegocio', 'retrieve_platos']),
    sortBy(param) {
      //this.platos.sort((a, b) => (a[param] < b[param] ? -1 : 1))
    },

    platoAgregado(plato) {
      this.mensaje = "Increible! Agregaste un nuevo Plato."
      //this.platos.push(plato)
      this.snackbar = true
    },

    platoActualizado(plato) {
      this.mensaje = "Excelente!... Modificaste un Plato."
      this.snackbar = true
    },
  
    estadoPlato(estado) {
      if (estado == 0) {
        return "inactive"
      } else {
        return "active"
      }
    },

    toggle(event, id) {
      this.$store
        .dispatch("update_estado_plato", { estadoplato: event, id: id })
        .then(response => {
          //console.log(response)
        })
        .catch(errore => {
          console.log(errore)
        })
    },    
  },

  computed: {
    ...mapGetters(['platos'])
  },

  created() {
    let user = this.$store.getters.authUser
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }
    this.iduser = user.id

    //this.fetchCategorias()
    this.retrieve_platos()
    this.retrieveNegocio()

    //this.fetchPlatos()
    /* this.$root.$on("platoAdded", unPlato => {
      this.platoAgregado(unPlato)
    }) */
  },  
}
</script>

<style scoped>
.plato.active {
  border-left: 4px solid #83ebb6;
  margin-left: 0px;
}

.plato.inactive {
  border-left: 4px solid #f83e70;
  margin-left: 0px;
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #83ebb6;
}

.theme--light.v-size--small.chip.inactive {
  background: #f83e70;
}
</style>
