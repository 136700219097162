<template>
  <v-dialog persistent v-model="dialog" width="1100px">
    <template v-slot:activator="{ on }">
      <v-btn fab large v-on="on" class="verde">
        <v-icon large>mdi-plus-thick</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="8">
            <v-form ref="formReceta" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline skim--text">Nueva Receta</span>
                </v-card-title>
                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="8" sm="8" md="8">
                        <v-select
                          class="mt-0"
                          v-model="id"
                          :error-messages="errorPlatos"
                          clearable
                          dense
                          flat
                          outlined
                          rounded
                          :items="platos"
                          item-text="nombreplato"
                          item-value="id"
                          chips
                          label="Plato"
                          single-line
                          :rules="[v => !!v || 'Plato es requerido']"
                          required
                          v-on:change="changeRoute"
                        ></v-select>
                      </v-col>
                      <v-col cols="4" sm="4" md="4">
                        <v-text-field
                          v-model="npax"
                          label="NPax"
                          type="number"
                          :min="0"
                          autocomplete="off"
                          :rules="requiredRule"
                          dense
                          flat
                          outlined
                          rounded
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="descripcion"
                          label="Descripcion"
                          autocomplete="off"
                          dense
                          flat
                          outlined
                          rounded
                          :rules="[v => !!v || 'Plato es requerido']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <NuevoGramaje @addGramaje="agregarGramaje" :productos="productos" />
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <v-checkbox
                          v-model="checkbox"
                          class="px-6"
                          label="Aplicar al Plato?"
                          required
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" sm="8" md="8">
                        <v-chip
                          v-if="ingredientesError!=''"
                          dark
                          color="red"
                          close
                        >{{ingredientesError}}</v-chip>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="py-2">
                      <v-col cols="12">
                        <v-simple-table dense height="160px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  colspan="3"
                                  class="gris text-center white--text"
                                >Gramaje por receta</th>
                                <th colspan="2" class="wood text-center">Costo mercado</th>
                                <th colspan="1" class="gris text-center white--text">Costo gramaje</th>
                              </tr>
                              <tr>
                                <th class="cancel text-left">Producto</th>
                                <th class="cancel text-left">CANT.</th>
                                <th class="cancel text-left text-center">UNID.</th>
                                <th class="coffeemilk text-left">Bs.</th>
                                <th class="coffeemilk text-left text-center">UNID.</th>
                                <th class="verde text-left text-center">TOTALES</th>
                                <th class="text-left text-center">Accion</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in ingredientes" :key="item.name">
                                <td class="text-left">{{ item.producto }}</td>
                                <td class="text-right">{{ item.cantidad }}</td>
                                <td class="text-center">{{ item.unidad1 }}</td>
                                <td class="text-right">{{ item.costomercado }}</td>
                                <td class="text-center">{{ item.unidad2 }}</td>
                                <td class="text-right">{{ item.total }}</td>
                                <td>
                                  <v-btn small icon color="red" @click="remover(item)">
                                    <v-icon small>fas fa-trash-alt</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!valid"
                    rounded
                    class="verde mr-4 white--text"
                    @click="submit"
                    :loading="loading"
                  >Guardar</v-btn>
                  <v-btn text class="skim--text" @click="close">Cancelar</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card height="100%" width="100%">
              <div class="px-1 caption px-2 py-2">
                Costo Variable Unitario:
                <v-chip class="px-1 subtitle-1 float-right">{{calcularTotal}}</v-chip>
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="item in calculos" :key="item.name">
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-right">{{ item.total }}</td>
                      <td class="text-center">{{item.medida}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider></v-divider>
              <p class="px-3 py-2 font-italic font-weight-light">IVA 14.94%</p>
            </v-card>
          </v-col>
        </v-row>
        <!--                 <v-row>
                    <pre>{{ingredientes}}</pre>
        </v-row>-->
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import NuevoGramaje from "@/components/recetas/NuevoGramaje.vue";

export default {
  name: "NuevaReceta",
  components: {
    NuevoGramaje
  },

  props: {
    platos: Array
  },

  data() {
    return {
      dialog: false,
      valid: false,
      lazy: false,
      loading: false,

      descripcion: "",
      fecha: "",
      npax: 1,
      margenerror: 8,
      checkbox: false,
      id: 0,

      errorPlatos: "",
      usuario: null,

      totalGramaje: 0,

      ingredientes: [],

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      calculos: [
        {
          name: "Cont. Pax",
          total: 1,
          medida: "Unid"
        },
        {
          name: "Cost. Vari. Unit",
          total: 0,
          medida: "Bs"
        },
        {
          name: "Margen de Error (8%)",
          total: 0,
          medida: "Bs"
        },
        {
          name: "Cost. Total Var. Unit.",
          total: 0,
          medida: "Bs"
        },
        {
          name: "Costo Unitario",
          total: 0,
          medida: "Bs"
        },
        {
          name: "Utilidad (65%)",
          total: 0,
          medida: "Bs"
        },
        {
          name: "Prec. de Venta",
          total: 0,
          medida: "Bs"
        },
        {
          name: "Prec. Facturado",
          total: 0,
          medida: "Bs"
        }
      ],

      ingredientesError: "",
      productos: null
    };
  },

  methods: {
    changeRoute() {},

    close() {
      this.dialog = false;
    },

    submit() {
      if (this.$refs.form.validate()) {
        if (this.ingredientes.length > 0) {
          this.loading = true;

          let formData = {
            npax: this.npax,
            descripcion: this.descripcion,
            costovariableunitario: this.calculos[1].total,
            margenerror: this.calculos[2].total,
            costo_totalunitario: this.calculos[3].total,
            costo_unitario: this.calculos[4].total,
            utilidad: this.calculos[5].total,
            precioventa: this.calculos[6].total,
            preciofacturado: this.calculos[7].total,

            users_id: this.usuario.idu,
            platos_id: this.id,
            ingredientes: this.ingredientes,
            aplicar: this.checkbox
          };

          this.$store
            .dispatch("create_receta", formData)
            .then(response => {
              this.loading = false;
              this.valid = true;

              let salida = response.data.data;

              this.dialog = false;

              formData.id = salida.id;
              formData.fecha = salida.fecha;
              formData.nombreplato = salida.nombreplato;

              this.$emit("recetaAdded", formData);
            })
            .catch(err => {
              if (err.response.status == 422) {
                console.log(err);
              }
            });
        } else {
          this.ingredientesError = "Completar al menos un ingrediente";
        }
      }
    },

    agregarGramaje(gramaje) {
      this.ingredientes.push(gramaje);
      this.ingredientesError = "";
    },

    remover(item) {
      const index = this.ingredientes.indexOf(item);
      this.ingredientes.splice(index, 1);
      if (this.ingredientes.length == 0) {
        this.ingredientesError = "Completar al menos un ingrediente";
      } else {
        this.ingredientesError = "";
      }
    },

    fetchProductos() {
      this.$store
        .dispatch("retrieve_productos", { idu: this.usuario.idu })
        .then(response => {
          this.productos = response;
          this.productos.sort((a, b) => (a['name'] < b['name'] ? -1 : 1))
        })
        .catch(err => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchProductos();
          }
        });
    },

  },

  created() {
    let usuario = this.$store.getters.authUser;
    if (typeof usuario.name === "undefined") {
      usuario = JSON.parse(usuario);
    }
    this.usuario = usuario;
    this.fetchProductos();
  },

  computed: {
    calcularTotal() {
      let suma = 0;
      if (this.ingredientes.length > 0) {
        this.ingredientes.forEach(function(valor, indice, array) {
          suma = Number(suma) + Number(valor.total);
        });
        //this.costovariableunitario = suma
        this.calculos[1].total = suma.toFixed(2);
        let me = Number((this.margenerror * suma) / 100).toFixed(2);
        this.calculos[2].total = me;
        let sum = Number(suma) + Number(me);
        this.calculos[3].total = sum.toFixed(2);
        this.calculos[4].total = sum.toFixed(2);
        let uti = Number((sum * 65) / 100).toFixed(2);
        this.calculos[5].total = uti;
        let precioventa = Number(uti) + Number(sum);
        this.calculos[6].total = precioventa.toFixed(2);
        let iva = Number((precioventa * 14.94) / 100).toFixed(2);
        this.calculos[7].total = (Number(precioventa) + Number(iva)).toFixed(2);
      }

      return suma.toFixed(2);
    }
  },

  watch: {
    npax(value, oldVal) {
      if (value > 0) {
        this.calculos[0].total = value;
      }
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}
</style>
