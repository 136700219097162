<script>

import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartdata: {
      labels: Array,
      datasets: [
        {
          label: "Ventas 2020",
          backgroundColor: "#f87979",
          data: Array
        }
      ]
    }
  },

  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),

  mounted() {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>