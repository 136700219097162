<template>
  <div>
    <v-row no-gutters class="pb-2 pt-2 text-center">
      <v-col sm="1" md="1">
        <div class="py-4 font-weight-light">
          <template v-if="contador!=null">{{contador}}.-</template>
          <template v-else>{{index+1}}.-</template>
        </div>
      </v-col>
      <v-col sm="2" md="2" class="pl-2 pb-2 pt-2">
        <v-avatar color="secondary" size="30">
          <img v-if="salida.picture!=null" :src="salida.picture" :alt="salida.name" />
          <img v-else src="@/assets/images/mf.png" :alt="salida.name" />
        </v-avatar>
      </v-col>
      <v-col sm="3" md="3" class="px-3 pt-3 text-left">
        <div class="font-weight-thin">{{salida.name}}</div>
      </v-col>

      <v-col sm="3" md="3">
        <div class="font-weight-thin">Fecha</div>
        <v-chip class="font-weight-thin" color="latte" x-small dark>
          <strong v-html="salida.fecha_salida"></strong>
        </v-chip>
      </v-col>
      <v-col sm="3" md="3">
        <div class="font-weight-thin">Cantidad</div>
        <v-chip color="primary" small>
          <strong v-html="salida.cantidad_salida"></strong>
        </v-chip>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: "Entrada",
  props: {
    salida: Object,
    index: Number,
    contador: Number
  },
  data() {
    return {};
  }
};
</script>

<style>
</style>