<template>
  <v-container fluid class="gradient">
    <v-snackbar v-model="snackbar" :timeout="6000" top color="green" class="title">
      <span>{{texto}}</span>
      <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-form class="px-6" ref="form">
      <v-layout row wrap>
        <v-flex xs12 md7>
          <v-card>
            <v-card-title>
              Datos de Mi Negocio
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="nombrenegocio"
                      label="Nombre del Negocio"
                      filled
                      rounded
                      :rules="requiredRule"
                      :error-messages="errorNombre"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="direccion"
                      label="Dirección"
                      filled
                      rounded
                      :rules="requiredRule"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="telefonos"
                      filled
                      rounded
                      label="Teléfono"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="celulares"
                      filled
                      rounded
                      label="Celular"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="nit"
                      filled
                      rounded
                      :error-messages="errorNit"
                      label="NIT"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="nautorizacion"
                      filled
                      rounded
                      :error-messages="errorAuto"
                      label="# Autorizacion"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-btn
                    :disabled="!valid"
                    @click="submit"
                    class="verde white--text"
                    :loading="loading"
                    rounded
                  >
                    Actualizar                    
                  </v-btn>
                  <v-btn text class="skim--text" @click="volver">
                    Cancelar                    
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 md5>
          <v-card align="center" height="100%">
            <v-img v-if="logo!=null&&!vistaPrevia" :src="logo" alt aspect-ratio="1.5" contain></v-img>

            <image-uploader
              :preview="vistaPrevia"
              :className="['fileinput', { 'fileinput--loaded': hasImage }]"
              capture="environment"
              :debug="1"
              doNotResize="gif"
              :autoRotate="false"
              outputFormat="verbose"
              @input="setImage"
              :quality="0.8"
              @onUpload="startImageResize"
              @onComplete="endImageResize"
              :scaleRatio="0.9"
            >
              <label for="fileInput" slot="upload-label" class="upload-caption">
                <h3>
                  <v-icon x-large>mdi-image</v-icon>
                  {{ hasImage ? "Cambiar" : "Subir imagen"}}
                </h3>
              </label>
            </image-uploader>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import ImageUploader from "vue-image-upload-resize"

export default {
  name: "Negocio",

  components: {
    ImageUploader
  },

  data() {
    return {
      valid: false,
      loading: false,

      nombrenegocio: "",
      direccion: "",
      telefonos: "",
      celulares: "",
      nit: "",
      nautorizacion: "",
      logo: "",

      inputRules: [
        v => !!v || "Este Campo es requerido",
        v => v.length >= 3 || "Minimo 3 caracteres"
      ],
      requiredRule: [v => !!v || "Este Campo es requerido"],

      errorNombre: "",
      errorNit: "",
      errorAuto: "",

      hasImage: false,
      vistaPrevia: false,
      type: "",
      user: null,
      image: null,

      snackbar: false,
      texto: ""
    }
  },

  methods: {
    volver() {
      this.$router.go(-1)
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.hasImage) {
          var blob = ""
          var datax = this.image.dataUrl
          var block = datax.split("")
          var contentType = block[0].split(":")[1]
          var realData = block[1].split(",")[1]

          blob = b64toBlob(realData, contentType)
          let ext = this.image.info.name
          this.type = ext.split(".").pop()

          let iData = {
            image: blob,
            type: this.type
          }

          this.$store
            .dispatch("image_upload", iData)
            .then(response => {
              this.logo = response
              setTimeout(this.updateNegocio(), 3000)
            })
            .catch(err => {
              console.log("negocio.image_upload: " + err)
            })
        } else {
          this.updateNegocio()
        }
      }
    },

    updateNegocio() {
      let formData = {
        id: this.user.nid,
        nombrenegocio: this.nombrenegocio,
        direccion: this.direccion,
        telefonos: this.telefonos,
        celulares: this.celulares,
        nit: this.nit,
        nautorizacion: this.nautorizacion,
        logo: this.logo
      }
      this.$store
        .dispatch("update_negocio", formData)
        .then(respuesta => {
          this.$root.$emit("cambiarNegocio", {
            negocio: this.nombrenegocio,
            logo: this.logo
          })

          this.loading = false
          this.texto = "Datos del Negocio actualizados."
          this.snackbar = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    checkNombreNegocio(value) {
      this.$store
        .dispatch("check_nombrenegocio", {
          nombrenegocio: value,
          id: this.user.nid
        })
        .then(response => {
          this.errorNombre = ""
          this.valid = true
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorNombre = err.response.data.message.nombrenegocio[0]
            this.errorNombre = this.errorNombre.replace(
              "nombrenegocio",
              "Nombre de Plato"
            )
            this.valid = false
          }
        })
    },

    checkNit(value) {
      this.$store
        .dispatch("check_nit", { nit: value, id: this.user.nid })
        .then(response => {
          this.errorNit = ""
          this.valid = true
        })
        .catch(err => {
          if (err.response.status == 422) {
            this.errorNit = err.response.data.message.nit[0]
            this.errorNit = this.errorNit.replace("nit", "Nit")
            this.valid = false
          }
        })
    },

    checkNauto(value) {
      this.$store
        .dispatch("check_nauto", { nautorizacion: value, id: this.user.nid })
        .then(response => {
          this.errorAuto = ""
          this.valid = true
        })
        .catch(err => {
          //console.log(err.response.data.status)
          if (err.response.status == 422) {
            this.errorAuto = err.response.data.message.nautorizacion[0]
            this.errorAuto = this.errorAuto.replace(
              "nautorizacion",
              "Numero de Autorizacion"
            )
            this.valid = false
          }
        })
    },

    startImageResize() {
      this.vistaPrevia = true
      this.hasImage = true
    },

    endImageResize() {},

    setImage(output) {
      this.hasImage = true
      this.vistaPrevia = true
      this.image = output
    },

    close() {
      this.$router.go(-1)
    },

    fetchNegocio(idn) {
      this.$store
        .dispatch("retrieve_negocio", { id: idn })
        .then(response => {
          let negocio = response
          this.nombrenegocio = negocio.nombrenegocio
          this.direccion = negocio.direccion
          this.telefonos = negocio.telefonos
          this.celulares = negocio.celulares
          this.nit = negocio.nit
          this.nautorizacion = negocio.nautorizacion
          this.logo = negocio.logo
          if ($this.logo != null) {
            this.vistaPrevia = false
            this.hasImage = false
          } else {
            this.vistaPrevia = true
            this.hasImage = true
          }
          this.valid= false
        })
        .catch(err => {
          if (err == 401) {
            this.$store.dispatch("refesh_token")
            this.fetchNegocio(idn)
          }
        })
    }
  },

  created() {
    let user = this.$store.getters.authUser
    if (typeof user.name === "undefined") {
      user = JSON.parse(user)
    }
    this.user = user
    this.fetchNegocio(user.nid)
  },

  watch: {
    nombrenegocio(value, oldVal) {
      if (value) {
        this.checkNombreNegocio(value)
      }
    },
    nit(value, oldVal) {
      if (value) {
        this.checkNit(value)
      }
    },
    nautorizacion(value, oldVal) {
      if (value) {
        this.checkNauto(value)
      }
    }
  }
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || ""
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  var blob = new Blob(byteArrays, { type: contentType })
  return blob
}
</script>

<style >
  #fileInput {
    display: none;
  }
  h1,
  h2 {
    font-weight: normal;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .my-8 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
</style>