import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

Vue.config.devtools = true

//axios.defaults.baseURL = 'http://localhost/api-pos-main/public/api'
axios.defaults.baseURL = 'https://vandersoft.com/services/public/api'

//axios.defaults.withCredentials = true

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') || {},
    platos: null,
    ventas: [],
    recetas:[],
    ingredientes:[],
    providers: null,
    productos: [],
    entradas: [],
    salidas: [],
    grupos: [],
    fileName: null,
    negocio: null,
    categories: [],
    copyPlatos: null
  },

  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token) {
      state.status = 'success'
      state.token = token
    },
    auth_error(state, error) {
      state.status = error
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.user = {}
    },
    auth_user(state, user){
      state.user = user
    },
    email_success(state, respuesta){
      state.status = respuesta
    },
    email_error(state, error){
      state.status = error
    },

    retrieveImage(state, fileName) {
      state.status = 'reading image '+fileName
    },
    SET_CATEGORIES(state, categories) {
      state.categorias = JSON.parse(categories)
    },
    expires(state){
      state.status = 'token timeout'
    },
    fileName(state, filename){
      state.fileName = filename
    },
    new_cat_request(state) {
      state.status = 'creando categoria'
    },
    cat_request(state) {
      state.status = 'validando'
    },
    cat_success(state, respuesta){
      state.status = respuesta
    },
    cat_error(state, error) {
      state.status = error
    },
    update_cat_request(state){
      state.status = 'updating categoria'
    },
    user_request(state) {
      state.status = 'validando email'
    },
    update_user_request(state){
      state.status = 'updating user'
    },
    destroyToken(state) {
      state.token = null
    },
    image_request(state) {
      state.status = 'solicitando imagen'
    },

    //platos
    plato_request(state) {
      state.status = 'cheking plato'
    },
    SET_PLATOS(state, payload) {
      state.platos = payload
      state.copyPlatos = payload
    },

    plato_error(state, error) {
      state.status = error
    },
    update_plato_request(state){
      state.status = 'updating plato'
    },
    delete_plato_request(state){
      state.status = 'deleting plato'
    },

    //ventas
    retrieveVentas(state, ventas) {
      state.ventas = ventas
    },
    
    //recetas
    retrieveRecetas(state, recetas) {
      state.recetas = recetas
    },
    receta_error(state, error) {
      state.status = error
    },
    //ingredientes
    retrieveIngredientes(state, ingredientes) {
      state.ingredientes = ingredientes
    },
    //providers
    SET_PROVIDERS(state, payload) {
      state.providers = payload
    },

    ADD_PROVIDER(state, payload){
      state.providers.push(payload)
    },

    //productos
    retrieveProductos(state, productos) {
      state.productos = productos
    },
    //entradas
    retrieveEntradas(state, entradas) {
      state.entradas = entradas
    },
    //salidas
    retrieveSalidas(state, salidas) {
      state.salidas = salidas
    },
    //grupos
    retrieveGrupos(state, grupos) {
      state.grupos = grupos
    },

    SET_NEGOCIO(state, payload){
      state.negocio = payload
      state.categories =  JSON.parse(state.negocio.categories)
    },

    UPDATE_NEGOCIO_CAT(state, payload){
      let categories = JSON.parse(state.negocio.categories)
      let category = categories.find(elem=>elem.id==payload.id)
      if(category!=undefined){
        Object.assign(category, payload)
        state.negocio.categories = JSON.stringify(categories)
      }
    },

    ADD_CAT_NEGOCIO(state, payload){
      let cats = JSON.parse(state.negocio.categories)
      cats.push(payload)
      state.negocio.categories = JSON.stringify(cats)
    },

    PLATO_ADD(state, payload){
      state.platos.push(payload)
    },

    UPDATE_PLATO(state, payload){
      let plato = state.platos.find(elem=>elem.id==payload.id)
      if(plato!=undefined){
        plato.nombreplato = payload.nombreplato
        plato.precioventa = payload.precioventa
        plato.precioprepa = payload.precioprepa
        plato.category = payload.category
      }
    },

    UPDATE_PLATO_IMAGE(state, payload){
      let plato = state.platos.find(elem=>elem.id==payload.id)
      if(plato!=undefined){
        plato.foto1 = payload.foto
      }
    },
    FILTRAR_PLATOS(state, nombrecat){
      
      //state.platos = state.copyPlatos
      state.platos = state.copyPlatos.filter(plato=> plato.category==nombrecat )

    }

  },// end-mutations  
  
  actions: {
    
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: '/login', data: user, method: 'POST' })
          .then(resp => {            
            //console.log(resp)
            const token = resp.data.token
            const user = resp.data.user
            localStorage.setItem('token', token) 
            localStorage.setItem('user', JSON.stringify(user))
            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', token)
            commit('auth_user', user)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },

    setUser( {commit}, userdata ){
      return new Promise(  (resolve, reject) =>{
        commit('auth_request')
        localStorage.setItem('user', JSON.stringify(userdata) )
        commit('auth_user', userdata)
        resolve(true)
      })
    },

    email_existe({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/mail', data: email, method: 'POST' })
          .then(resp => {            
            const respuesta = resp.data.success
            commit('email_success', respuesta)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },

    image_upload(context, archive) {
      return new Promise((resolve, reject) => {

        const formData = new FormData()
        formData.append('imagen', archive.image)
        formData.append('type', archive.type)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios.post('/files', formData,
           {
            headers: {'Content-Type': 'multipart/form-data'}
           } 
        ).then( response => {
          //console.log(response.data.imageName)
          //commit('fileName', response.fileName)
          resolve(response.data.imageName)
        }).catch(err => {
          context.commit('auth_error', err)
          reject( err )
        })

      })
    },

    delete_file( context, dataFile) {
      return new Promise((resolve, reject) => {
        //context.commit('delete_file_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/files/delete/'+dataFile.archivo, data: dataFile, method: 'GET' })

      })
    },

    refesh_token(context){      
        context.commit('expires')
        axios({ url: '/refresh', method: 'POST' })
        .then(resp => {
          const token = resp.data.token            
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token
          context.commit('auth_success', token)          
        })
        .catch(err => {          
          console.log( err )
        })
    },

    existeEmail2( context, user) {
      //console.info(id)
      return new Promise((resolve, reject) => {
        context.commit('user_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/users/email2/'+user.id, data: user, method: 'POST' })
          .then(resp => {
            //context.commit('cat_request')
            resolve(resp)
          })
          .catch(err => {
            context.commit('cat_error', err)
            reject( err )
          })
      })
    },
    
    update_user(context, user) {
      return new Promise((resolve, reject) => {
        context.commit('update_user_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/users/'+user.id, data: user, method: 'PUT' })
          .then(resp => {
            delete user.password
            localStorage.setItem('user', JSON.stringify(user) )
            context.commit('auth_user', user)
            resolve(resp)
          })
          .catch(err => {
            context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    imagen( context, image) {
        return new Promise((resolve, reject) => {
          context.commit('image_request')
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
          axios({ url: '/files/'+image.imageName, data: {}, method: 'GET' })
            .then(resp => {
              resolve(resp)
            })
            .catch(err => {
              //commit('image_error', err)
              reject( err )
            })
        })
    },

    update_user_image(context, user) { //to-do mantener el nombre de imagen
      return new Promise((resolve, reject) => {
        context.commit('update_user_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/users/image/'+user.id, data: user, method: 'POST' })
          .then(resp => {       
            localStorage.setItem('user', JSON.stringify(user))
            resolve(resp)
          })
          .catch(err => {
            context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    /* CATEGORIAS */

    retrieveCategorias(context) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/negocios/categories', method: 'POST' })
          .then(response => {
            context.commit('SET_CATEGORIES', response.data)
            //resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            //reject(error.response.status)
          })
      })
    },

    retrieveNegocio(context){
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/negocio', data: {
          token: context.state.token
        }, method: 'GET' })
          .then(response => {
            context.commit('SET_NEGOCIO', response.data)
            resolve('ok')
          })
          .catch(error => {
            console.log(error)
            //reject(error.response.status)
          })
      })
    },

    retrieve_categorias2(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/categorias/negocio', data: formData, method: 'POST' })
          .then(resp => {
            context.commit('retrieveCategorias2', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    create_category(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        
        axios({ url: 'negocio/cat', data: data, method: 'POST' })
          .then(resp => {
            data.id = resp.data
            context.commit('ADD_CAT_NEGOCIO', data)
            resolve('ok')
          })
          .catch(err => {
            context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    nameCategory(context, namecat) {
      return new Promise((resolve, reject) => {
        context.commit('cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/categorias/catname', data: namecat, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('cat_error', err)
            reject( err )
          })
      })
    },

    nameCategory2( context, cate) {
      //console.info(id)
      return new Promise((resolve, reject) => {
        context.commit('cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'categorias/catname2/'+cate.cid, data: cate, method: 'POST' })
          .then(resp => {
            //context.commit('cat_request')
            resolve(resp)
          })
          .catch(err => {
            context.commit('cat_error', err)
            reject( err )
          })
      })
    },

    update_category(context, fdata) {
      return new Promise((resolve, reject) => {
        context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'negocio/update/cat', data: fdata, method: 'PUT' })
          .then(resp =>{
            context.commit('UPDATE_NEGOCIO_CAT', fdata)
            resolve('ok')
          })
          .catch(err => {
            context.commit('auth_error', err)
            reject( err )
          })
      })
    },

      /* PLATOS */

    retrieve_platos(context) {
      return new Promise((resolve, reject) => {
        
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'platos', method: 'GET' })
          .then(resp => {
            context.commit('SET_PLATOS', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            //context.commit('auth_error', err)
            reject(error.response.status)
          })
      })
    },

    retrieve_platos_activos(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/platos/user/state/'+formData.id+'/'+formData.state, data: formData, method: 'GET' })
          .then(resp => {
            context.commit('SET_PLATOS', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            //context.commit('auth_error', err)
            reject(error.response.status)
          })
      })
    },

    retrievePlatosNegocio(context) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'platos/user/negocio', method: 'GET' })
          .then(resp => {
            context.commit('SET_PLATOS', resp.data.platos)
            context.commit('SET_NEGOCIO', resp.data.negocio)
            resolve('ok')
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    create_plato(context, fdata) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'platos', data: fdata, method: 'POST' })
          .then(resp => {
            fdata.id = resp.data
            fdata.estadoplato = 1
            context.commit('PLATO_ADD', fdata)
            resolve('ok')
          })
          .catch(err => {
            console.error(err)
            reject( err )
          })
      })
    },

    update_plato( context, plato) {
      return new Promise((resolve, reject) => {
        context.commit('update_plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'platos/'+plato.id, data: plato, method: 'PUT' })
          .then(resp => {
            context.commit('UPDATE_PLATO', plato)
            resolve('ok')
          })
          .catch(err => {
            context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    updatePlatoImage( context, fdata) {
      return new Promise((resolve, reject) => {
        
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'platos/change/image/'+fdata.id, data: fdata, method: 'PUT' })
          .then(resp => {
            context.commit('UPDATE_PLATO_IMAGE', fdata)
            resolve('ok')
          })
          .catch(err => {
            console.log(err)
            reject( err )
          })
      })
    },

    delete_plato( context, plato) {
      return new Promise((resolve, reject) => {        
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/platos/'+plato.id, data: plato, method: 'DELETE' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    name_plato(context, nameplato) {
      return new Promise((resolve, reject) => {
        context.commit('plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/platos/platoname', data: nameplato, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    name_plato2( context, plato) {
      //console.info(id)
      return new Promise((resolve, reject) => {
        context.commit('plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'platos/platoname2/'+plato.id, data: plato, method: 'POST' })
          .then(resp => {
            context.commit('plato_request')
            resolve(resp)
          })
          .catch(err => {
            context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    update_estado_plato( context, plato) {
      return new Promise((resolve, reject) => {
        context.commit('update_plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/platos/update/'+plato.id, data: plato, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    //VENTAS

    retrieve_ventas(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'ventas/user', data: formData, method: 'POST' })
          .then(resp => {
            context.commit('retrieveVentas', resp.data.result.data)
            resolve(resp.data)
          })
          .catch(error => {
            //context.commit('auth_error', err)
            reject(error.response.status)
          })
      })
    },

    retrieve_ventas_hoy(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/ventas/hoy', data: formData, method: 'POST' })
          .then(resp => {
            //context.commit('retrieveVentasHoy', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            //context.commit('auth_error', err)
            reject(error.response.status)
          })
      })
    },

    createVenta(context, venta) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'ventas', data: venta, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    verify_user( context, dataUser) {      
      return new Promise((resolve, reject) => {
        
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/users/verify/'+dataUser.id, data: dataUser, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject( err )
          })
      })
    },

    update_estado_venta( context, venta) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/ventas/'+venta.id, data: venta, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject( err )
          })
      })
    },


    //DETALLE
    store_detalle(context, dataDetalle) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: 'detalles', data: dataDetalle, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    retrieve_detalles(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios.get('/detalles/user/'+data.id)
          .then(response => {
            //context.commit('retrieve_detalles', response.data.data)
            resolve(response.data.data)
          })
          .catch(error => {
            //console.log(error.response.status)
            reject(error.response.status)
          })
      })
    },

    retrieve_ranking(context) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios.get('detalles/report/ventas')
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => {
            console.log(error)
            reject(error.response.status)
          })
      })
    },

    //NEGOCIO

    retrieve_negocio(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios.get('/negocios/'+data.id)
          .then(response => {
            //context.commit('retrieveCategorias', response.data.data)
            resolve(response.data.data)
          })
          .catch(error => {
            //console.log(error.response.status)
            reject(error.response.status)
          })
      })
    },

    check_nombrenegocio(context, data) {
      return new Promise((resolve, reject) => {
        //context.commit('plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/negocios/negocioname2/'+data.id, data: data, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('plato_error', err)
            reject( err )
          })
      })
    },

    check_nit(context, data) {
      return new Promise((resolve, reject) => {
        //context.commit('plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/negocios/nit2/'+data.id, data: data, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {            
            reject( err )
          })
      })
    },

    check_nauto(context, data) {
      return new Promise((resolve, reject) => {
        //context.commit('plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/negocios/nauto2/'+data.id, data: data, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject( err )
          })
      })
    },

    update_negocio(context, data) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/negocios/'+data.id, data: data, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    //RECETAS

    retrieve_recetas(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/recetas/user/'+formData.id, data: formData, method: 'GET' })
          .then(resp => {
            context.commit('retrieveRecetas', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            //context.commit('auth_error', err)
            reject(error)
          })
      })
    },

    retrieve_recetas_plato(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/recetas/plato/'+formData.id, data: formData, method: 'GET' })
          .then(resp => {
            //context.commit('retrieveRecetas', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            //context.commit('auth_error', err)
            reject(error)
          })
      })
    },

    create_receta(context, receta) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/recetas', data: receta, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('receta_error', err)
            reject( err )
          })
      })
    },

    update_receta(context, receta) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/recetas/'+receta.id, data: receta, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    //INGREDIENTES

    retrieve_ingredientes(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/ingredientes/receta/'+formData.id, data: formData, method: 'GET' })
          .then(resp => {
            context.commit('retrieveIngredientes', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            //context.commit('auth_error', err)
            reject(error)
          })
      })
    },

    remove_ingrediente(context, data) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/ingredientes/'+data.id, data: data, method: 'DELETE' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    //providers

    retrieveProviders(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/providers/user/'+formData.id, method: 'GET' })
          .then(resp => {
            context.commit('SET_PROVIDERS', resp.data)
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    createProvider(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/providers', data: data, method: 'POST' })
          .then(resp => {
            data.state = "active"
            data.id = resp.data.data
            context.commit("ADD_PROVIDER", data)
            resolve(true)
          })
          .catch(err => {
            //context.commit('receta_error', err)
            reject( err )
          })
      })
    },

    update_provider(context, provider) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/providers/'+provider.id, data: provider, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    //productos

    retrieve_productos(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/productos/user/'+formData.id, data: formData, method: 'GET' })
          .then(resp => {
            context.commit('retrieveProductos', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    retrieve_producto(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/productos/detalle', data: formData, method: 'POST' })
          .then(resp => {
            //context.commit('retrieveProducto', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    create_producto(context, producto) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/productos', data: producto, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('receta_error', err)
            reject( err )
          })
      })
    },

    update_producto(context, producto) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/productos/'+producto.id, data: producto, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    check_pname(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/productos/name/', data: data, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject( err )
          })
      })
    },

    //entradas

    retrieve_entradas(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/entradas/user', data: formData, method: 'POST' })
          .then(resp => {
            context.commit('retrieveEntradas', resp.data.result.data)
            resolve(resp.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    retrieve_entradas2(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/entradas/user/'+formData.id+'?page='+formData.page , 
        data: formData, method: 'GET' })
          .then(resp => {
            context.commit('retrieveEntradas', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    retrieve_entradas_limit(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/entradas/limit/'+formData.id, data: formData, method: 'GET' })
          .then(resp => {
            context.commit('retrieveEntradas', resp.data.result.data)
            resolve(resp.data.result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    create_entrada(context, entrada) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/entradas', data: entrada, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('receta_error', err)
            reject( err )
          })
      })
    },

    update_entrada(context, entrada) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/entradas/'+entrada.id, data: entrada, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('auth_error', err)
            reject( err )
          })
      })
    },
    //salidas

    retrieve_salidas(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/salidas/user', data: formData, method: 'POST' })
          .then(resp => {
            context.commit('retrieveSalidas', resp.data.result.data)
            resolve(resp.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    retrieve_salidas_limit(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/salidas/limit/'+formData.id, data: formData, method: 'GET' })
          .then(resp => {
            context.commit('retrieveSalidas', resp.data.result.data)
            resolve(resp.data.result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    create_salida(context, salida) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/salidas', data: salida, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('receta_error', err)
            reject( err )
          })
      })
    },

    update_salida(context, salida) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/salidas/'+salida.id, data: salida, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('auth_error', err)
            reject( err )
          })
      })
    },
    //grupos

    retrieve_grupos(context, formData) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/grupos/user/'+formData.id, data: formData, method: 'GET' })
          .then(resp => {
            context.commit('retrieveGrupos', resp.data.data)
            resolve(resp.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    create_grupo(context, grupo) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/grupos', data: grupo, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('receta_error', err)
            reject( err )
          })
      })
    },

    update_grupo(context, grupo) {
      return new Promise((resolve, reject) => {
        //context.commit('update_cat_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/grupos/'+grupo.id, data: grupo, method: 'PUT' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            //context.commit('auth_error', err)
            reject( err )
          })
      })
    },

    check_gname(context, data) {
      return new Promise((resolve, reject) => {
        //context.commit('plato_request')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios({ url: '/grupos/gname/', data: data, method: 'POST' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject( err )
          })
      })
    },

    retrieve_ventas_anio(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        axios.get('ventas/'+data.year)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => {
            console.log(error)
            reject(error.response.status)
          })
      })
    },

    filtrarPlatos(context, nombrecat){
      context.commit('FILTRAR_PLATOS', nombrecat)
    }

  }, //end-actions

  getters: {
    //let user = this.$store.getters.authUser;
    authStatus: state => state.status,
    authUser:  state => state.user,
    providers: state => state.providers,

    platos : state => state.platos,

    categories : state => {
      if(state.negocio!=null){
        if(state.negocio.categories!=''){
          return JSON.parse(state.negocio.categories)
        }
      }
      return []
    },

    ventas : state => {
      return state.ventas
    },

    recetas : state => {
      return state.recetas
    },
    entradas : state => {
      return state.entradas
    },
    salidas : state => {
      return state.salidas
    },
    grupos : state => {
      return state.grupos
    },

    loggedIn(state) {
      return state.token !== null
    },

    isLoggedIn: state => !!state.token,
    negocio: state => state.negocio
  }
})