<template>
  <div class="gradient">
    <v-card>
      <v-toolbar color="accent" dark src="@/assets/images/v1.jpg" height="100px" class="mb-2">
        <v-toolbar-title>Entradas</v-toolbar-title>
        <div class="ml-4 subtitle-1">últimas</div>
        <v-spacer></v-spacer>
        <NuevaEntrada @entradaAdded="entradaAgregada" :productos="productos" />
      </v-toolbar>
      <v-divider></v-divider>

      <v-sheet
        v-if="!entradas"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="px-3 pt-3 pb-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="800"
          type="table-heading, table-thead, table-tbody, table-tfoot"
        ></v-skeleton-loader>
      </v-sheet>

      <Entrada
        v-else
        :entrada="entrada"
        :index="index"
        v-for="(entrada, index) in entradas"
        :key="entrada.id"
      />
      <!--       <pre>
        {{entradas}}
      </pre>-->
      <div class="text-center py-2 graysuave" v-if="rows > 7">
        <v-btn x-small rounded color="verde" @click="gotoEntradas">Lista Completa</v-btn>
      </div>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="6000" top color="green" class="title">
      <span>{{ mensaje }}</span>
      <v-btn color="white" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <!--     <pre>
        {{rows}}
    </pre>-->
  </div>
</template>
<script>
import Entrada from "@/components/inventario/Entrada.vue";
import NuevaEntrada from "@/components/inventario/NuevaEntrada.vue";

export default {
  inject: ["theme"],
  name: "entradas",
  components: { Entrada, NuevaEntrada },
  props: {
    productos: Array
  },
  data() {
    return {
      entradas: null,
      rows: -1,
      snackbar: false,
      iduser: 0,
      mensaje: ""
    };
  },

  methods: {
    fetchEntradas() {
      this.$store
        .dispatch("retrieve_entradas_limit", { idu: this.iduser })
        .then(response => {
          this.entradas = response.data;
          this.rows = response.rows;
        })
        .catch(err => {
          console.log(err);
          if (err == 401) {
            this.$store.dispatch("refesh_token");
            this.fetchEntradas();
          }
        });
    },

    entradaAgregada(entrada) {
      this.entradas.push(entrada);

      var found = this.productos.find(obj => obj.id == entrada.productos_id);

      if (found != undefined) {
        found.entrada =
          Number(found.entrada) + Number(entrada.cantidad_entrada);
        found.saldo = Number(found.saldo) + Number(entrada.cantidad_entrada);
        this.productos[found.index] = found;
      }

      this.mensaje = "Excelente, Entrada realizada";
      this.snackbar = true;
    },

    gotoEntradas() {
      this.$router.push("inventario/entradas");
    }
  },

  created() {
    let user = this.$store.getters.authUser;
    if (typeof user.name === "undefined") {
      user = JSON.parse(user);
    }
    this.iduser = user.idu;
    this.fetchEntradas();
  },

  computed: {}
};
</script>

<style scoped></style>
