<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" fab>
        <v-icon>mdi-palette</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-color-picker
          v-model="colore"
          mode="hexa"
          class="ma-2">
        </v-color-picker>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="sendColor"
        >ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-menu>
</template>

<script>
  export default {
    name: "ColorMenu",
    props: {
      color: String
    },
    data() {
      return {
        menu: false,
        colore: "accent",
      }
    },
    methods: {
      sendColor() {
        this.$emit('setColor', this.colore)
        this.menu=false
      }
    },
    created () {
      this.colore = this.color
    },
  }
</script>

<style scoped>
</style>